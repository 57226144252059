import { GoogleApiWrapper, InfoWindow, Map, Marker } from 'google-maps-react';
import React from 'react';
import checkDataExistsOrNot from './checkDataExistsOrNot';
import grayMarker from "./icons/grayMarker.svg";
import blueMarker from "./icons/blueMarker.svg";

function LocationMap(props) {
    const mapStyles = {
      height: props.divHeight ? props.divHeight : 'calc(100% - 186px)',
      width: 'calc(100% - 86px)',
      borderRadius: "16px",
      marginTop:"12px"
    };

    const [defaultCenter, setDefaultCenter] = React.useState({lat: 0,lng: 0});
    const [markerClicked, setMarkerClicked] = React.useState(false);
    const [activeMarker, setActiveMarker] = React.useState({});
    const [macId, setMacId] = React.useState("");
    const [count, setCount] = React.useState();

    const showInfoWindow = (props, marker, e) => {
        setActiveMarker(marker);
        setMacId(props.macId);
        setCount(props.count);
        setMarkerClicked(true);
    }

    return (
            <>
               <Map
                  style={mapStyles}
                  google={props.google}
                  initialCenter={checkDataExistsOrNot(props.fromDevicePage) ? {
                    lat: checkDataExistsOrNot(props.markers) ? props.markers[0].lat : 0,
                    lng: checkDataExistsOrNot(props.markers) ? props.markers[0].lng : 0
                  } : {lat:0,lng:0}}
                  zoom={checkDataExistsOrNot(props.fromDevicePage) ?  checkDataExistsOrNot(props.markers) ? 55 : 2 : 0}
               >
                  {
                     props.markers.map((location)=>
                         <Marker
                           icon={{
                              url: checkDataExistsOrNot(props.fromDevicePage) ? (location.connStatus === 1) ? blueMarker : grayMarker
                               : location.count === 1 ? (location.connStatus === 1) ? "http://maps.google.com/mapfiles/ms/icons/green-dot.png" : "https://maps.google.com/mapfiles/ms/icons/red-dot.png"
                               : "http://maps.google.com/mapfiles/ms/icons/purple-dot.png",
                           }}
                           position={{lat: location.lat, lng: location.lng}} macId={location.macId} count={location.count} onClick={showInfoWindow}
                         />

                  )}
                  <InfoWindow
                    marker={activeMarker}
                    visible={markerClicked}
                  >
                    <div>
                      {
                       checkDataExistsOrNot(props.fromDevicePage) ?
                         <div>
                           <strong>MacId : </strong>{macId}
                         </div>
                       :
                         <div>
                           <strong>Gateway count : </strong>{count}<br/>
                           <strong>MacId(s) : </strong><br/>{macId}
                         </div>
                      }
                    </div>
                 </InfoWindow>
               </Map>
           </>
    );
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDeECOSttALpeHsCzRGWm8U4s4pBX6qZ38'
})(LocationMap);
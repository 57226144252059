import { createTheme } from '@material-ui/core/styles';
import { ArrowBack, ArrowLeft, ArrowRight, CropPortrait, Group, LocationCity, Notifications, PersonalVideo, AccountCircle, Business, FeedbackOutlined } from '@mui/icons-material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LanguageIcon from '@mui/icons-material/Language';
import { AppBar, Box, CssBaseline, Divider, Drawer, Fab, IconButton, List, ListItem, ListItemText, Toolbar, Tooltip, Typography, Menu, MenuItem, Switch } from '@mui/material';
import CaribouLogo from 'cariboutechnologieslogo.png';
import AuthClient from 'clients/AuthClient';
import frontendServiceClient from 'clients/FrontendClient';
import IotServiceClient from 'clients/IotServiceClient';
import AgentConfig from 'components/agentConfig/AgentConfig';
import FeedBack from 'components/FeedBack/FeedBack';
import Dashboard from 'components/dashboard/Dashboard.js';
import GlobalDashboard from 'components/dashboard/GlobalDashboard.js';
import Dashboard1 from 'components/dashboard1/Dashboard1.js';
import PortalUsers from "components/DashboardPortalUsers/PortalUsers.js";
import Devices from 'components/devices/devices.js';
import Appheader from 'components/header/Appheader.js';
import MqttEventHandler from 'components/Mqtt/MqttEventHandler';
import Alerts from 'components/userAlerts/Alerts.js';
import checkDataExistsOrNot from 'helper-components/checkDataExistsOrNot.js';
import _ from 'lodash';
import PropTypes from 'prop-types';
import PubSubChannels from 'pubsub/PubSubChannels';
import React from 'react';
import { useLocation, useNavigate, Link } from "react-router-dom";
import 'stylesheet/SideNav.css';
import UserSessionStorage from 'userSession/UserSessionStorage';
import dashboardIcon from './icons/dashboardIcon.svg';
import dashboardSelectedIcon from './icons/dashboardSelectedIcon.svg';
import gatewaysIcon from './icons/gatewaysIcon.svg';
import usersIcon from './icons/usersIcon.svg';
import alertsIcon from './icons/alertsIcon.svg';
import agentConfigIcon from './icons/agentConfigIcon.svg';
import cariboutechlogo from './icons/caribouLogo.png';
import favIconImg from "./icons/cariboulogo.svg";
import gatewaysSelectedIcon from './icons/gatewaysSelectedIcon.svg';
import usersSelectedIcon from './icons/usersSelectedIcon.svg';
import alertsSelectedIcon from './icons/alertsSelectedIcon.svg';
import agentConfigSelectedIcon from './icons/agentConfigSelectedIcon.svg';
import 'stylesheet/header.css';
import MenuIcon from '@mui/icons-material/Menu';
import DisableTwoFactorAuthentication from 'components/accountMenu/DisableTwoFactorAuthentication';
import TwoFactorAuthentication from 'components/accountMenu/TwoFactorAuthentication';
import DeletePortalUsersDialog from 'components/DashboardPortalUsers/DeletePortalUserDialog';

function SideNav(props) {

  let adminViews = [];

  if (JSON.parse(sessionStorage.userDetails).user.scopeId !== "66c8765e927a13321c085d4b") {
    if (JSON.parse(sessionStorage.userDetails).permissions.includes("PORTAL_USER_READ")) {
      adminViews.push("Users");
    }
    if (JSON.parse(sessionStorage.userDetails).permissions.includes("ALERTS_READ")) {
      adminViews.push("Alerts");
      adminViews.push("Agent Config");
    }
  } else {
      adminViews.push("Alerts");
  }
  if(JSON.parse(sessionStorage.userDetails).user.scopeId === "66c8765e927a13321c085d4b" || JSON.parse(sessionStorage.userDetails).isRootUser){
      adminViews.push("Feedback");
  }

  const checkIsFreeTier = () => {
    if (!sessionStorage.sidenavAlreadySelectedItem){
      if (sessionStorage.tenantName === "FreeTier"){
          return "Gateways";
      }else {
          return "Dashboard";
      }
    }else {
      return "windowSelectedItem";
    }
  };

  const {windows} = props;
  const location = useLocation();
  const [selectedListItem, setSelectedListIem] = React.useState(!sessionStorage.sidenavAlreadySelectedItem ? checkIsFreeTier() : "Dashboard");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [drawerWidth, setDrawerWidth] = React.useState(240);
  const [mqttFrontend, setMqttFrontend] = React.useState(false);
  const [mqttIOT, setMqttIOT] = React.useState(false);
  const [mqttGateway, setMqttGateway] = React.useState(false);
  const [mqttAuth, setMqttAuth] = React.useState(false);
  const [tenantName, setTenantName] = React.useState(sessionStorage.tenantName);
  const [menuItemIconColor, setMenuItemIconColor] = React.useState(
    checkIsFreeTier() === "Dashboard" ? 'DashboardSideNavItem' : (!sessionStorage.sidenavAlreadySelectedItem ? 'GatewaysSideNavItem' : (sessionStorage.sidenavAlreadySelectedItem === "Users" || sessionStorage.sidenavAlreadySelectedItem === "Alerts" || sessionStorage.sidenavAlreadySelectedItem === "Agent Config" || sessionStorage.sidenavAlreadySelectedItem === "Feedback" ?  sessionStorage.sidenavAlreadySelectedItem + "AdminSideNavItem" : sessionStorage.sidenavAlreadySelectedItem  + "SideNavItem"))
  );
  const [menuItemTextColor, setMenuItemTextColor] = React.useState(
    checkIsFreeTier() === "Dashboard" ? 'DashboardSideNavItemText' : (!sessionStorage.sidenavAlreadySelectedItem ? 'GatewaysSideNavItemText' : (sessionStorage.sidenavAlreadySelectedItem === "Users" || sessionStorage.sidenavAlreadySelectedItem === "Alerts" || sessionStorage.sidenavAlreadySelectedItem === "Agent Config" || sessionStorage.sidenavAlreadySelectedItem === "Feedback" ?  sessionStorage.sidenavAlreadySelectedItem + "AdminSideNavItemText" : sessionStorage.sidenavAlreadySelectedItem  + "SideNavItemText"))
  );
  const [menuItemLeftBorder, setMenuItemLeftBorder] = React.useState(
    checkIsFreeTier() === "Dashboard" ? 'DashboardSideNavItemDiv' : (!sessionStorage.sidenavAlreadySelectedItem ? "GatewaysSideNavItemDiv" : (sessionStorage.sidenavAlreadySelectedItem === "Users" || sessionStorage.sidenavAlreadySelectedItem === "Alerts" || sessionStorage.sidenavAlreadySelectedItem === "Agent Config" || sessionStorage.sidenavAlreadySelectedItem === "Feedback" ?  sessionStorage.sidenavAlreadySelectedItem + "AdminSideNavItemDiv" : sessionStorage.sidenavAlreadySelectedItem  + "SideNavItemDiv"))
  );
  if (window.location.hash.indexOf("dashboard") !== -1 && selectedListItem !== "Dashboard"){
    setSelectedListIem("Dashboard");
    setMenuItemIconColor("DashboardSideNavItem");
    setMenuItemTextColor("DashboardSideNavItemText");
    setMenuItemLeftBorder("DashboardSideNavItemDiv");
  }else if(window.location.hash.indexOf("gateways") !== -1 && selectedListItem !== "Gateways"){
      setSelectedListIem("Gateways");
      setMenuItemIconColor("GatewaysSideNavItem");
      setMenuItemTextColor("GatewaysSideNavItemText");
      setMenuItemLeftBorder("GatewaysSideNavItemDiv")
  }else if(window.location.hash.indexOf("globalDashboard") !== -1 && selectedListItem !== "Global Dashboard"){
     setSelectedListIem("Global Dashboard")
     setMenuItemIconColor("Global DashboardSideNavItem");
     setMenuItemTextColor("Global DashboardSideNavItemText");
     setMenuItemLeftBorder("Global DashboardSideNavItemDiv")
  }else if(window.location.hash.indexOf("portal_users") !== -1 && selectedListItem !== "Users"){
     setSelectedListIem("Users")
     setMenuItemIconColor("UsersAdminSideNavItem");
     setMenuItemTextColor("UsersAdminSideNavItemText");
     setMenuItemLeftBorder("UsersAdminSideNavItemDiv")
  }else if(window.location.hash.indexOf("alerts") !== -1 && selectedListItem !== "Alerts"){
     setSelectedListIem("Alerts")
     setMenuItemIconColor("AlertsAdminSideNavItem");
     setMenuItemTextColor("AlertsAdminSideNavItemText");
     setMenuItemLeftBorder("AlertsAdminSideNavItemDiv")
  }else if(window.location.hash.indexOf("agent-config") !== -1 && selectedListItem !== "Agent Config"){
     setSelectedListIem("Agent Config")
     setMenuItemIconColor("Agent ConfigAdminSideNavItem");
     setMenuItemTextColor("Agent ConfigAdminSideNavItemText");
     setMenuItemLeftBorder("Agent ConfigAdminSideNavItemDiv")
  }else if(window.location.hash.indexOf("feedback") !== -1 && selectedListItem !== "Feedback"){
     setSelectedListIem("Feedback")
     setMenuItemIconColor("FeedbackAdminSideNavItem");
     setMenuItemTextColor("FeedbackAdminSideNavItemText");
     setMenuItemLeftBorder("FeedbackAdminSideNavItemDiv")
  }
  const [firstListBlock, setFirstListBlock] = React.useState([]);

  const [frontEndVersion, setFrontEndVersion] = React.useState("");

  const [iotVersion, setIotVersion] = React.useState("");

  const [gatewayVersion, setGatewayVersion] = React.useState("");

  const [authVersion, setAuthVersion] = React.useState("");

  const[themePrimaryColor, setThemePrimaryColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7")

  const[themeSecondaryColor, setThemeSecondaryColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#fff")

  const [logoUrl, setLogoUrl] = React.useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [enableTwoFaDialog, setEnableTwoFaDialog] = React.useState(false);
  const [disableTwoFaDialog, setDisableTwoFaDialog] = React.useState(false);
  const [toggleState, setToggleState] = React.useState(false);
  const [showRemoveUserDialog, setShowRemoveUserDialog] = React.useState(false);
  const [displayFlag, setDisplayFlag] = React.useState(true);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const isConnected = (status) => {
    return status.toString() === "connected" ? true : false;
  }

  const updateMqttSTatus = () => {
    let status = UserSessionStorage.getMqttStatus();
    setMqttFrontend(status)
    if (!status){
      setMqttAuth(status);
      setMqttGateway(status);
      setMqttIOT(status);
    }
  }

  let checkMqttInterval = setInterval(() => {
     updateMqttSTatus();
  }, 2000);

  let gatewayServiceStatusEventChannel, iotServiceStatusEventChannel, authServiceStatusEventChannel = null;


  const gatewayServiceStatusEventHandler = (message) => {
    if(isConnected(message)){
      setMqttGateway(true)
    }else {
      setMqttGateway(false)
    }
  }

  const iotServiceStatusEventHandler = (message) => {
    if(isConnected(message)){
      setMqttIOT(true)
    }else {
      setMqttIOT(false)
    }
  }

  const authServiceStatusEventHandler = (message) => {
    if(isConnected(message)){
      setMqttAuth(true)
    }else {
      setMqttAuth(false)
    }
  }

  const themeAndLogoChangeEventHandler = (message) => {
     if (message.toString() === "created") {
        AuthClient.getHirarchyOnlineDeviceCount("page").then((response) => {
            _.forEach(response.children,function(value){
                if (value.scope.id === sessionStorage.tenantId) {
                    setThemePrimaryColor(value.scope.primaryThemeColor);
                    setThemeSecondaryColor(value.scope.secondaryThemeColor);
                    setLogoUrl(value.scope.logoUrl);
                }
            });
        })
     }
  }

  const checkMqqttSTatusForFIGA = () => {
    gatewayServiceStatusEventChannel = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.GATEWAY_SERVICE_STATUS, "sideNav", gatewayServiceStatusEventHandler);

    iotServiceStatusEventChannel = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.IOT_SERVICE_STATUS, "sideNav" , iotServiceStatusEventHandler);

    authServiceStatusEventChannel = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.AUTH_SERVICE_STATUS, "sideNav", authServiceStatusEventHandler);

    window.themeAndLogoChangeEvent = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.SCOPES.replace("scopeId",sessionStorage.tenantId), "Appheader", themeAndLogoChangeEventHandler);

  }

  const getFigaVersions = () => {
    frontendServiceClient.getFrontendAppDetails().then((response)=>{
      setFrontEndVersion(response.version);
    }).catch((error)=>{})

    IotServiceClient.getIotAppDetails("sideNav").then((response)=>{
      setIotVersion(response.version);
    }).catch((error)=>{})

    AuthClient.getAuthAppDetails("sideNav").then((response)=>{
      setAuthVersion(response.version);
    }).catch((error)=>{})
  }


  const handleHashChange = () => {
    window.addEventListener('popstate', (event) => {
      let hash = window.location.hash;
      if (sessionStorage.sidenavAlreadySelectedItem && hash !== "" && hash){
        let locationHash = "";

        if (hash.indexOf("list_view") !== -1 || hash.indexOf("grid_view") !== -1){
          locationHash = "Gateways"
        }else if (hash.indexOf("/globalDashboard") !== -1){
          locationHash = "Global Dashboard";
        }else if (hash.indexOf("agent-config") !== -1){
          locationHash = "Agent Config";
        }else if (hash.indexOf("feedback") !== -1){
          locationHash = "Feedback";
        }else {
          locationHash = hash;
        }

        if (locationHash.toLowerCase().indexOf(sessionStorage.sidenavAlreadySelectedItem.toLowerCase()) === -1){
          setTimeout(() => {
            if (window.location.hash.toLowerCase().indexOf("dashboard") !== -1) {
              sessionStorage.sidenavAlreadySelectedItem = "Dashboard";
            }else if (window.location.hash.toLowerCase().indexOf("globalDashboard") !== -1){
              sessionStorage.sidenavAlreadySelectedItem = "Global Dashboard";
            }else if (window.location.hash.toLowerCase().indexOf("list_view") !== -1) {
              sessionStorage.lockView = "listView"
              sessionStorage.sidenavAlreadySelectedItem = "Gateways";

            }else if (window.location.hash.toLowerCase().indexOf("grid_view") !== -1) {
              sessionStorage.lockView = "gridView"
              sessionStorage.sidenavAlreadySelectedItem = "Gateways";

            }else if (window.location.hash.toLowerCase().indexOf("/portal_users") !== -1) {
              sessionStorage.sidenavAlreadySelectedItem = "Users";

            }else if (window.location.hash.toLowerCase().indexOf("alerts") !== -1) {
              sessionStorage.sidenavAlreadySelectedItem = "Alerts";

            }else if (window.location.hash.toLowerCase().indexOf('agent-config') !== -1) {
              sessionStorage.sidenavAlreadySelectedItem = "Agent Config";

            }else if (window.location.hash.toLowerCase().indexOf('feedback') !== -1) {
              sessionStorage.sidenavAlreadySelectedItem = "Feedback";

            }
          }, 1500);
        }
      }
    });
  }

  React.useEffect(() => {
    if (sessionStorage.tenantName === 'Caribou Technologies') {
      setFirstListBlock([
        'Dashboard',
        // 'Dashboard1',
        'Gateways',
        // 'LocksGrid',
        'Global Dashboard',
      ]);
    }else {
      setFirstListBlock(['Dashboard', 'Gateways']);
    }

    if (JSON.parse(sessionStorage.userDetails).user.secureAuthentication === true) {
       setToggleState(true);
    } else {
       setToggleState(false);
    }
    handleHashChange();
    getFigaVersions();
    checkMqqttSTatusForFIGA();
    setThemePrimaryColor(sessionStorage.tenantPrimaryThemeColor);
    setThemeSecondaryColor(sessionStorage.tenantSecondaryThemeColor);
    window.twoFAStatusChannel = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.TWO_FACTOR_AUTHENTICATION_CHANNEL.replace("scopeId",JSON.parse(sessionStorage.userDetails).user.scopeId), "Appheader",toggleTwoFaState);
    return () => {
      if (gatewayServiceStatusEventChannel){
        gatewayServiceStatusEventChannel.unsubscribe();
      }
      if (iotServiceStatusEventChannel) {
        iotServiceStatusEventChannel.unsubscribe();
      }
      if (authServiceStatusEventChannel) {
        authServiceStatusEventChannel.unsubscribe();
      }
      if (window.themeAndLogoChangeEvent) {
         window.themeAndLogoChangeEvent.unsubscribe();
      }
      clearInterval(checkMqttInterval);
      IotServiceClient.abortSignal("sideNav");
      delete sessionStorage.sidenavAlreadySelectedItem;
      if(window.twoFAStatusChannel) {
        window.twoFAStatusChannel.unsubscribe();
        delete window.twoFAStatusChannel;
      }
    }
  }, []);

  const toggleTwoFaState = (data) => {
     data = checkDataExistsOrNot(data) ? JSON.parse(data.toString()) : "" ;
     if (data.hasOwnProperty("user")){
        setToggleState(data.user.secureAuthentication);
        let json = {};
        json.isRootUser = JSON.parse(sessionStorage.userDetails).isRootUser;
        json.permissions = JSON.parse(sessionStorage.userDetails).permissions;
        json.user = data.user;
        sessionStorage.userDetails = JSON.stringify(json);
     }
  }

  const changeUrlHash = (listItem) => {

    if (listItem === "Dashboard"){
      window.location.hash = "/cli/dashboard" + "?scopeId="+ sessionStorage.tenantId;
      location.hash = "#/cli/dashboard" + "?scopeId="+ sessionStorage.tenantId;
    }else if (listItem === "Global Dashboard"){
      window.location.hash = "/cli/globalDashboard" + "?scopeId="+ sessionStorage.tenantId;
      location.hash = "#/cli/globalDashboard" + "?scopeId="+ sessionStorage.tenantId;
    }else if (listItem === "Gateways") {
      let urlHash = "/cli/gateways/list_view" + "?scopeId="+ sessionStorage.tenantId;
      if (!sessionStorage.lockView || sessionStorage.lockView === "listView"){
        window.location.hash = urlHash.replace("grid_view","list_view");
        location.hash = "#" + urlHash.replace("grid_view","list_view");
      }else {
        window.location.hash = urlHash.replace("list_view","grid_view");
        location.hash = "#" + urlHash.replace("list_view","grid_view");
      }
    }else if (listItem === "Users") {
      window.location.hash = "/cli/portal_users" + "?scopeId="+ sessionStorage.tenantId;
      location.hash = "#/cli/portal_users" + "?scopeId="+ sessionStorage.tenantId;
    }else if (listItem === "Alerts") {
      let payload = {
        "email": JSON.parse(sessionStorage.userDetails).user.email,
        "alertsViewed": {"ti" : [new Date().getTime()]}
      };
      AuthClient.saveUserActivities(payload,"alertsViewed").then((response) => {});
      window.location.hash = "/cli/alerts" + "?scopeId="+ sessionStorage.tenantId;
      location.hash = "#/cli/alerts" + "?scopeId="+ sessionStorage.tenantId;
    }else if (listItem === "Agent Config") {
      window.location.hash = "/cli/agent-config" + "?scopeId="+ sessionStorage.tenantId;
      location.hash = "#/cli/agent-config" + "?scopeId="+ sessionStorage.tenantId;
    }else if (listItem === "Feedback") {
      window.location.hash = "/cli/feedback" + "?scopeId="+ sessionStorage.tenantId;
      location.hash = "#/cli/feedback" + "?scopeId="+ sessionStorage.tenantId;
    }
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const changeDrawerWidth = (presentWidth) => {
    presentWidth === 240 ? setDrawerWidth(80) : setDrawerWidth(240);
  };

  const checkDrawerWidth = () => {
    let checkCondition;
    drawerWidth !== 240 ? (checkCondition = true) : (checkCondition = false);
    return checkCondition;
  };

  const changeSideNavTextColor = (id, listItem) => {
    setMenuItemIconColor(id);
    setMenuItemTextColor(id + 'Text');
    setMenuItemLeftBorder(id + 'Div');
    sessionStorage.sidenavAlreadySelectedItem = listItem;
    setSelectedListIem(listItem);
    changeUrlHash(listItem);
    handleDrawerToggle();
    if (listItem !== "Gateways"){
      delete sessionStorage.navigatedFromDashboard
      delete sessionStorage.lockView;
    }
  };

  const reloadPage = (url) => {
    if (url === "/engineering-View"){
      sessionStorage.engineeringView = true;
    }
    if (url === "/logout"){
        let payload = {
          "scopeId": JSON.parse(sessionStorage.userDetails).user.scopeId,
          "email": JSON.parse(sessionStorage.userDetails).user.email,
          "logout" : {"ti" : [new Date().getTime()]}
        };
        AuthClient.saveLoginOrLogoutActivity(payload,sessionStorage.activityKey,"logout").then((response) => {});
        sessionStorage.clear();
      }
      window.location.hash = url;
      location.hash = '#'+url;
  };

  const enableOrDisableTwoFa = (event) => {
    event.stopPropagation();
    setToggleState(!toggleState);
    setAnchorEl(null);
    if (JSON.parse(sessionStorage.userDetails).user.secureAuthentication === true) {
        setDisableTwoFaDialog(true);
    } else {
        setEnableTwoFaDialog(true);
    }
  }

  const showRemoveUserAlertDialog = () => {
      setShowRemoveUserDialog(true);
  }

  const handleCloseRemoveUserAlertDialog = () => {
      setShowRemoveUserDialog(false);
  }

  const fabRedStyle = {
    color: "#3377FF",
    bgcolor: themePrimaryColor,
    '&:hover': {
      bgcolor: "#3377FF",
      color: themeSecondaryColor
    },
  };

  const toggleDisplayFlag = () => {
    setTimeout(() => {
        setDisplayFlag(true);
    },400)
  }

  const navigateBack = () => {
    if (window.location.hash !== "" && window.location.hash !== "#/" && window.location.hash !== "#/admin/tenants"){
      let isInsideSideNav = false;
      if(window.location.hash.indexOf("cli") !== -1){
          isInsideSideNav = true;
      }
      if(sessionStorage.isRootUser === "true"){
        delete sessionStorage.sidenavAlreadySelectedItem
        delete sessionStorage.tenantPrimaryThemeColor
        delete sessionStorage.tenantSecondaryThemeColor
        delete sessionStorage.logoUrl
        window.location.hash = "/admin/tenants";
        location.hash = "#/admin/tenants";
      }else {
        window.location.hash = "/cli/dashboard";
        location.hash = "#/cli/dashboard";
      }
      delete sessionStorage.sidenavAlreadySelectedItem;
    }
  }

  const drawer = (
    <div
      style={{
        height:
          checkDrawerWidth() === true
            ? `calc(100% - ${240}px)`
            : `calc(100% - ${84}px)`,
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      <Toolbar className="header_nav" sx={{ paddingRight:"16px !important", paddingLeft:drawerWidth <= 80 ? '14px !important':'none', marginTop:drawerWidth <= 80 ? '9px':'4px',justifyContent:"center"}}>
        <img style={{cursor: sessionStorage.isRootUser ? "pointer" : null}} onClick={() => sessionStorage.isRootUser ? navigateBack() : null} src={checkDrawerWidth() === true ? favIconImg : cariboutechlogo} alt="" width={checkDrawerWidth() === true ? 56 : 154} height={ checkDrawerWidth() === true ? 51 : 88}/>
      </Toolbar>
      <Box sx={{display:'flex', flexDirection:'column'}}>
          {
            checkDrawerWidth() !== true ?
              <Box sx={{height:"54px", fontSize:"20px", display:"flex",flexDirection:"row", width:"100%",justifyContent:'flex-start',alignItems:"center"}}>
                <Box sx={{width:"60px",height:"54px",display:"flex", justifyContent:"end",alignItems:"center"}}>
                  <Business sx={{height:"40px",width:"40px",position:"relative",left:"-5px"}} />
                </Box>
                <Box sx={{marginLeft:"10px",cursor:"auto"}}>
                  <Tooltip title={sessionStorage.tenantName} placement='top' arrow>
                    <Typography sx={{position:"relative",top:"4px",fontSize:"20px",fontWeight:"500"}}>
                      {sessionStorage.tenantName ? sessionStorage.tenantName.length > 12 ? sessionStorage.tenantName.substring(0,12)+"..." : sessionStorage.tenantName : ""}
                    </Typography>
                  </Tooltip>
                </Box>
              </Box>
            :
              null
          }
          <Divider sx={{borderBottomWidth: '2px' }}variant="middle"/>
      </Box>
        <Toolbar sx= {{position: "absolute",bottom: "88px",left: "-16px", paddingLeft: '12px !important'}}>
        {sessionStorage.isRootUser == 'true' ?
            <span
              style={{margin:"10px 0px 10px 18px"}}
            >
              <a onClick={() => {
                delete sessionStorage.sidenavAlreadySelectedItem
                delete sessionStorage.tenantPrimaryThemeColor
                delete sessionStorage.tenantSecondaryThemeColor
                delete sessionStorage.logoUrl
                window.location.hash= "/admin/tenants"
              }}>
                <Tooltip title="Back to Admin" placement='top' arrow>
                  <Fab
                    size='small'
                    aria-label="add"
                    sx={{...fabRedStyle, cursor: 'pointer', background: "#3377FF"}}
                  >
                    <ArrowBack sx={{color: '#fff'}}/>
                  </Fab>
                </Tooltip>
              </a>
            </span>
          :
            null
        }
      </Toolbar>
      <List sx={{paddingBottom: '0px'}}>
        {firstListBlock.map((text, index) => (
          <ListItem
            selected={text === selectedListItem}
            button
            key={text}
            className={text + 'SideNavItemDiv'}
            sx={{
              height: '48px',
              background: menuItemLeftBorder === text + 'SideNavItemDiv' ? 'white' + " !important" : null
            }}
            onClick={() => changeSideNavTextColor(text + 'SideNavItem', text)}
          >
            <ListItem
              className={text + 'SideNavItem'}
              sx={{
                color:
                  menuItemIconColor === text + 'SideNavItem'
                    ? '#fff'
                    : '#000000DE',
                    width:"40px",paddingLeft:"0px",
                    borderRadius: menuItemIconColor === text + 'SideNavItem' ? '30%' : "30%",
                    backgroundColor: menuItemIconColor === text + 'SideNavItem' ? "#3377FF" + " !important" : "#fff !important",                    
                    fontSize:"16px !important",
                    fontWeight:500
              }}
            >
                {text === 'Dashboard' ? (
                <img src={menuItemIconColor === text + 'SideNavItem' ? dashboardSelectedIcon : dashboardIcon} style={{marginLeft:'8px',fill:'blue'}}/>
              ) : text === 'Gateways' ? (
                <img src={menuItemIconColor === text + 'SideNavItem' ? gatewaysSelectedIcon : gatewaysIcon} style={{marginLeft:'11px'}}/>
              ) : text === 'Global Dashboard' ? (
                <img src={menuItemIconColor === text + 'SideNavItem' ? dashboardSelectedIcon : dashboardIcon} style={{marginLeft:'8px'}}/>
              ) : null}
            </ListItem>
            <ListItemText
              sx={{
                visibility: checkDrawerWidth() === true ? 'hidden' : 'visible',
                marginLeft:'15px',
                color: menuItemTextColor === text + 'SideNavItemText'  ? 'rgb(0,122,255)' : 'gray',
                fontSize:"16px",
                fontWeight:500
              }}
              disableTypography
              primary={text}
              className={text + 'SideNavItemText'}
            />
          </ListItem>
        ))}
      </List>
      {sessionStorage.tenantName !== 'FreeTier' && adminViews.length > 0 ? (
        <>
          <List sx={{paddingTop:'0px'}}>
            {
            adminViews.map((text, index) => (
              <ListItem
                selected={text === selectedListItem}
                button
                key={text}
                className={text + 'AdminSideNavItemDiv'}
                sx={{
                  height: '48px',
                  background: menuItemLeftBorder === text + 'AdminSideNavItemDiv' ? 'white' + " !important" : null
                }}
                onClick={() =>
                  changeSideNavTextColor(text + 'AdminSideNavItem', text)
                }
              >
                <ListItem
                  className={text + 'AdminSideNavItem'}
                  sx={{
                    color:
                      menuItemIconColor === text + 'AdminSideNavItem'
                        ? '#fff'
                        : 'gray',
                        width:"40px",
                        height:"40px",
                        paddingLeft:"0px",
                        borderRadius: menuItemIconColor === text + 'AdminSideNavItem' ? '30%' : '30%',
                        backgroundColor: menuItemIconColor === text + 'AdminSideNavItem' ? "#3377FF" + " !important" : '#fff !important',
                  }}
                >
                  {text === 'Users' ? (
                    <img src={menuItemIconColor === text + 'AdminSideNavItem' ? usersSelectedIcon : usersIcon} style={{marginLeft: '6px'}}/>
                  ) : text === 'Alerts' ? (
                    <img src={menuItemIconColor === text + 'AdminSideNavItem' ? alertsSelectedIcon : alertsIcon} style={{marginLeft: '10px'}}/>
                  ) : text === 'Agent Config' ? (
                    <img src={menuItemIconColor === text + 'AdminSideNavItem' ? agentConfigSelectedIcon : agentConfigIcon} style={{marginLeft: '6px'}}/>
                  ) : text === 'Feedback' ? (
                    <FeedbackOutlined sx={{marginLeft:'8px'}}></FeedbackOutlined>
                  ) : null}
                </ListItem>
                <ListItemText
                  sx={{
                    visibility:
                      checkDrawerWidth() === true ? 'hidden' : 'visible',
                    marginLeft:'15px',
                    fontWeight:500,
                    color:menuItemTextColor === text + 'AdminSideNavItemText' ? 'rgb(0,122,255)' : 'gray',
                    fontSize:"16px",
                    fontWeight:500
                  }}
                  disableTypography
                  primary={text}
                  className={text + 'AdminSideNavItemText'}
                />
              </ListItem>
            ))}
          </List>
        </>
      ) : null}
      <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          padding: '2px 14px 2px 2px',
          position: 'fixed',
          bottom: checkDrawerWidth() === true ? '40px' : '40px',
          background: '#fff',
          left:'-7px',flexDirection:"row"
        }}
      >
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ml: 2, color: '#3377FF'}}
          className="accountMenu"
        >
          <AccountCircle sx={{height:"inherit", width:"inherit"}} />
        </IconButton>
        {
          checkDrawerWidth() !== true && sessionStorage && sessionStorage.userDetails ?
            <Tooltip placement='top' title={JSON.parse(sessionStorage.userDetails).user.email} arrow>
              <Typography sx={{fontSize:"14px",position:"relative",top:"12px",left:"8px"}}>{ JSON.parse(sessionStorage.userDetails).user.email.length > 18 ? JSON.parse(sessionStorage.userDetails).user.email.substring(0,18) + "..." : JSON.parse(sessionStorage.userDetails).user.email }</Typography>
            </Tooltip>
          :
            null
        }
      </Box>
        <Menu
            className="account_popover"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                //bottom:"93px !important",
                //top: "342px !important",
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,

                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  bottom: -9,
                  left: 21,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{horizontal: 'center', vertical: 'bottom'}}
            anchorOrigin={{horizontal: 'center', vertical: 'top'}}
            sx={{mt: -2}}
          >
            <div className="row_1">
              <div className="name">{sessionStorage.userDetails ? JSON.parse(sessionStorage.userDetails).user.firstName + " " + JSON.parse(sessionStorage.userDetails).user.lastName : ""}</div>
              <div className="email">{sessionStorage.userDetails ? JSON.parse(sessionStorage.userDetails).user.email : ""}</div>
            </div>
            <Divider />
            <Link
              to="#/change-password"
              style={{textDecoration: 'unset', color: 'black'}}
              onClick={() => reloadPage('/change-password')}
            >
              <MenuItem>Change Password</MenuItem>
            </Link>
            <MenuItem onClick={(event) => {event.stopPropagation();}} >{!toggleState ? "Enable 2FA" : "Disable 2FA"}
              <span style={{marginLeft: '29px'}}>
                 <Tooltip title={ toggleState ? "Two-Factor Authentication Enabled" : "Two-Factor Authentication Disabled"} placement="top" arrow>
                    <Switch size='medium' checked={toggleState} onClick={(event) => enableOrDisableTwoFa(event)} />
                 </Tooltip>
              </span>
            </MenuItem>
            {
              sessionStorage.isRootUser === "true" && sessionStorage.userDetails && JSON.parse(sessionStorage.userDetails).permissions.includes("IOT_DEVICE_SCOPE_MIGRATE") ?
                <Link
                  to="#/upgrade-firmware"
                  style={{textDecoration: 'unset', color: 'black'}}
                  onClick={() => reloadPage('/upgrade-firmware')}
                >
                  <MenuItem sx={{mt: 1}}>Update Gateway Firmware</MenuItem>
                </Link>
              :
                null
            }
            {
              sessionStorage.isRootUser === "true" && sessionStorage.userDetails && JSON.parse(sessionStorage.userDetails).permissions.includes("IOT_DEVICE_SCOPE_MIGRATE") ?
                <Link
                  to="#/update-OS"
                  style={{textDecoration: 'unset', color: 'black'}}
                  onClick={() => reloadPage('/update-OS')}
                >
                  <MenuItem sx={{mt: 1}}>Update Gateway OS</MenuItem>
                </Link>
              :
                null
            }
            {
              sessionStorage.isRootUser === "true" && sessionStorage.userDetails && JSON.parse(sessionStorage.userDetails).permissions.includes("IOT_DEVICE_SCOPE_MIGRATE") ?
                <Link
                  to="#/portal-config"
                  style={{textDecoration: 'unset', color: 'black'}}
                  onClick={() => reloadPage('/portal-config')}
                >
                  <MenuItem sx={{mt: 1}}>Portal Configuration</MenuItem>
                </Link>
              :
                null
            }
           {
             sessionStorage.userDetails && JSON.parse(sessionStorage.userDetails).permissions.includes("SCOPE_UPDATE") && JSON.parse(sessionStorage.userDetails).user.scopeId !== "66c8765e927a13321c085d4b"?
                <Link
                  to="#/theme-config"
                  style={{textDecoration: 'unset', color: 'black'}}
                  onClick={() => reloadPage('/theme-config')}
                >
                  <MenuItem sx={{mt: 1}}>Theme Configuration</MenuItem>
                </Link>
              :
              null
            }
            <MenuItem onClick={() => showRemoveUserAlertDialog()} sx={{display: JSON.parse(sessionStorage.userDetails).user.scopeId === "66c8765e927a13321c085d4b" ? 'block' : 'none'}}> Remove your account </MenuItem>
            <Link
              to="#/logout"
              style={{textDecoration: 'unset', color: 'black'}}
              onClick={() => reloadPage('/logout')}
            >
              <MenuItem>Logout</MenuItem>
            </Link>
        </Menu>
      </React.Fragment>
    </div>
  );

  const container =
    windows !== undefined ? () => windows().document.body : undefined;

  const fabSecondaryStyle = {
    color: themeSecondaryColor,
    '&:hover' : {
      color: themeSecondaryColor
    }
  }

  const customBreakPointtheme = createTheme({
    breakpoints: {
      values: {
        mobile: 0,
        lg: 1179,
        desktop: 1280,
      },
    },
  });

  const customHiddenBreakPointtheme = createTheme({
      breakpoints: {
        values: {
          mobile: 0,
          lg: 1179,
          desktop: 1280,
        },
      },
   });

    const nav = useNavigate();
  return (
    <>
      <Box sx={{display: 'flex', overflowX: 'hidden',overflowY: selectedListItem === 'Gateways' ? 'hidden' : 'auto',height: selectedListItem === 'Gateways' ? '100%' : 'auto'}}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            display:{xs:'block',lg:'block',desktop:'none',mobile:'none',xl:'none'},
            '@media (min-width:1280px) and (max-width:1535px)': {
              display: 'none',
            }
          }}
        >
          <Toolbar className="header_nav" sx={{ paddingRight:"16px !important"}}>
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              theme={customBreakPointtheme}
              sx={{
                mr: 2,
                display: {
                  xs: 'block',
                  desktop: 'none',
                  width: '35px',
                  marginRight: '10px',
                },
                color:"grey"
              }}
            >
              <Tooltip title="Top Navbar" placement="right" arrow>
                <MenuIcon />
              </Tooltip>
            </IconButton>

            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              theme={customBreakPointtheme}
              sx={{
                mr: 2,
                display: {
                  lg: 'block',
                  mobile: 'none',
                  desktop: 'block',
                  width: '35px',
                  marginRight: '10px',
                  visibility:"hidden"
                },
                color:"grey"
              }}
            >
              <Tooltip title="Top Navbar" placement="right" arrow>
                <MenuIcon />
              </Tooltip>
            </IconButton>

            <img src={checkDrawerWidth() === true ? favIconImg : cariboutechlogo} alt="" width={checkDrawerWidth() === true ? 56 : 154} height={ checkDrawerWidth() === true ? 51 : 88}/>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
          aria-label="mailbox folders"
          className="sideNavigation"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              [`& .MuiDrawer-paper`]: {
                boxSizing: 'border-box',
                width: drawerWidth,
              },
            }}
            className="smallDevicesDrawer"
          >
            {mobileOpen ? drawer : null}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              [`& .MuiDrawer-paper`]: {
                boxSizing: 'border-box',
                width: drawerWidth,
                transition: 'width 0.3s ease',
              },
            }}
            className="largeDevicesDrawer"
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          theme={customBreakPointtheme}
          sx={{
            flexGrow: 1,
            p: 3,
            width: {
              mobile: '100%',
              desktop: `calc(100% - ${drawerWidth}px)`,
              padding: '0px',
              height: '100%',
            },
            background: 'rgb(244, 250, 254)',
          }}
        >

          {selectedListItem === 'Gateways' ? (
            <div style={{background: 'rgb(244, 250, 254)', margin: '10px', height: '100%'}}>
                 <Devices
                    openExtendedView={() => {
                    setDrawerWidth(80)
                    setDisplayFlag(false)}}
                    closeExtendedView={() => {
                    setDrawerWidth(240)
                    toggleDisplayFlag()}}
                 />
               <Box sx={{height:"50px"}}> &nbsp;</Box>
            </div>
          ) : selectedListItem === 'Users' ? (
             <div style={{background: 'rgb(244, 250, 254)', margin: '10px',height: 'calc(100vh - 20px)'}}>
                   <PortalUsers />
                <Box sx={{height:"50px"}}> &nbsp;</Box>
             </div>
          ) : selectedListItem === 'Alerts' ? (
            <div style={{background: 'rgb(244, 250, 254)', margin: '10px',height: 'calc(100vh - 20px)'}}>
                  <Alerts />
               <Box sx={{height:"50px"}}> &nbsp;</Box>
            </div>
          ) : selectedListItem === 'Agent Config' ? (
            <div style={{background: '#fff', margin: '10px'}}>
                  <AgentConfig />
               <Box sx={{height:"50px"}}> &nbsp;</Box>
            </div>
          ) : selectedListItem === 'Feedback' ? (
            <div style={{background: 'rgb(244, 250, 254)', margin: '15px',height:'calc(100vh - 30px)'}}>
                  <FeedBack />
            </div>
          ) : selectedListItem === 'Dashboard' ? (
            <div style={{margin: '10px', width:"100%",height: "calc(100vh - 20px)", overflow:"hidden"}}>
               { tenantName === "New Earth Solutions" ?
                    <Dashboard1 changePage={()=> {
                       window.clickEvent=0
                       changeSideNavTextColor("GatewaysSideNavItem", "Gateways")
                    }}
                    />
                 :
                     <Dashboard primaryColor={themePrimaryColor} secondaryColor={themeSecondaryColor} changePage={()=> {
                        window.clickEvent=0
                        changeSideNavTextColor("GatewaysSideNavItem", "Gateways")
                     }} />
              }
              <Box sx={{height:"50px"}}> &nbsp;</Box>
            </div>
          ): selectedListItem === 'Global Dashboard' ? (
             <div style={{margin: '10px'}}>
                   <GlobalDashboard changePage={()=> {
                       window.clickEvent=0
                       changeSideNavTextColor("LocksSideNavItem", "Locks")
                     }}
                   />
                <Box sx={{height:"50px"}}> &nbsp;</Box>
             </div>
          ): null}
        </Box>
      </Box>

      {
           enableTwoFaDialog ?
              <TwoFactorAuthentication
                 openDialog={enableTwoFaDialog}
                 setToggleToPreviousState={(res) => {
                    if (res === false) {
                      setToggleState(!toggleState);
                    }
                 }}
                 closeDialog={() => setEnableTwoFaDialog(false)}
              />
           :
           null
      }
      {
           disableTwoFaDialog ?
            <DisableTwoFactorAuthentication
                openDialog={disableTwoFaDialog}
                setToggleToPreviousState={() => setToggleState(!toggleState)}
                closeDialog={() => setDisableTwoFaDialog(false)}
            />
           :
           null
      }
      {
          showRemoveUserDialog ?
              <DeletePortalUsersDialog
                  title="Remove Your Account"
                  disable={false}
                  data={JSON.parse(sessionStorage.userDetails).user}
                  flag={true}
                  show_delete_permission_dialog={showRemoveUserDialog}
                  close_delete_permission_dialog={(value) =>
                    handleCloseRemoveUserAlertDialog(value)
                  }
                  dialog="deletePermissionDialog"
              />
          :
              null
      }
    </>
  );
}

SideNav.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  windows: PropTypes.func,
};

export default SideNav;

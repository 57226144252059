import React from 'react';
import { convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { Box, Button } from '@mui/material';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useSnackbar } from 'notistack';
import AuthClient from "clients/AuthClient";
import draftToHtml from "draftjs-to-html";


export default function FeedBack() {
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
    const [text, setText] = React.useState();
    const [submitFlag, setSubmitFlag] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const onEditorStateChange = function (editorState) {
        setEditorState(editorState);

        let val = editorState.getCurrentContent().getPlainText("\u0001");;
        setSubmitFlag(val.length > 0 ? true : false)

        let data = convertToRaw(editorState.getCurrentContent());
        setText(draftToHtml(data));
    };

    const handleBeforeInput = (input) => {
        const currentContent = editorState.getCurrentContent();
        const currentText = currentContent.getPlainText(); // Get the plain text

        // Allow input if there is already text present (not empty)
        if (currentText.length === 0 && input === ' ') {
          return 'handled'; // Block the input
        }
        return 'not-handled'; // Allow the input
    };

    const handleOnSubmit = () => {                
        let payload = {
            "email" : sessionStorage.hasOwnProperty("userDetails") ? JSON.parse(sessionStorage.userDetails).user.email : "",
            "body" : text
        }

        AuthClient.saveFeedback(payload).then((res) => {
            if(res.status >= 200 && res.status <= 300){
                setEditorState(EditorState.createEmpty());
                setSubmitFlag(false);
                enqueueSnackbar("Successfully submitted feedback");
            }else{
                enqueueSnackbar("Failed to submit feedback");
            }
        })
    }

    return (
        <>
            <Box sx={{display:'flex', flexDirection:'column', justifyContent:'center', height:'calc(100dvh - 20px)'}}>
                <Box sx={{fontFamily: "Poppins !important",fontSize:'20px', marginBottom:'10px'}}>
                   Feedback
                </Box>
                <Box sx={{height:'92%',padding:'10px', borderRadius: "20px", border: "2px solid #fff", background:"#fff", boxShadow: "7px 12px 16px -11px rgba(12, 12, 13, 0.4)"}}>
                         <Box sx={{height:'94%', padding:"10px", border: "1px solid black", overflow:'auto', borderRadius: "20px"}}>
                             <Editor
                               editorState={editorState}
                               toolbarClassName="toolbarClassName"
                               wrapperClassName="wrapperClassName"
                               editorClassName="editorClassName"
                               handleBeforeInput={handleBeforeInput}
                               onEditorStateChange={onEditorStateChange}
                             />
                         </Box>
                         <Box sx={{display:'flex', justifyContent:'center', marginTop:'10px'}}>
                             <Button variant='contained' sx={{backgroundColor: (submitFlag ? "blue" : "grey"), borderRadius: "10px"}} disabled={!submitFlag} onClick={() => handleOnSubmit()}>
                                submit
                             </Button>
                         </Box>
                </Box>
            </Box>
        </>
    )
}
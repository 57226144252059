import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery, useTheme, TextField, InputAdornment } from '@mui/material';
import { Close } from '@mui/icons-material';
import JobSchedulerClient from "clients/JobSchedulerClient";
import checkDataExistsOrNot from 'helper-components/checkDataExistsOrNot.js';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import PhoneInput, { getCountryCallingCode } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import MqttEventHandler from 'components/Mqtt/MqttEventHandler';
import PubSubChannels from 'pubsub/PubSubChannels';
import AuthClient from "clients/AuthClient";

const SubscribeOrUnsubscribeToSmsAlert = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { enqueueSnackbar } = useSnackbar();
    const [mobileNumber, setMobileNumber] = React.useState();
    const [countryFlag,setCountryFlag] = React.useState('US');
    const [countryCode, setCountryCode] = React.useState('+1');
    const [submitted, setSubmitted] = React.useState(false);

    const subscribeToSmsAlerts = () => {
        if(!submitted){
            setSubmitted(true);
            let alreadySaved = false;
            let alertPayload = {
              id : props.data.id,
              alertType : props.data.alertType,
              alertName : props.data.alertName,
              waitingTime : props.data.waitingTime,
              thresholdType : props.data.thresholdType,
              threshold : props.data.threshold,
              emailAlert : props.data.emailAlert,
              smsAlert : props.sendSmsAlert,
              scopeId: sessionStorage.tenantId,
              userId: props.data.userId
            }

            if (!props.mobileNumberExists) {
                let phoneNumber=checkDataExistsOrNot(countryCode) && checkDataExistsOrNot(mobileNumber) ? countryCode+mobileNumber : "";

                let receiverData=[];
                alreadySaved = false;
                _.forEach(props.data.receivers, (receiver) => {
                  if (receiver.email === JSON.parse(sessionStorage.userDetails).user.email){
                    receiver["phoneNumber"] = phoneNumber;
                    alreadySaved = true;

                  }
                  receiverData.push(receiver);
                })
                if (!alreadySaved){
                  receiverData.push({
                    "email": null,
                    "phoneNumber": phoneNumber,
                  });
                }

                let userPayload = {
                  "id": props.userData.id,
                  "email": props.userData.email,
                  "firstName": props.userData.firstName,
                  "lastName": props.userData.lastName,
                  "scopeId": sessionStorage.tenantId,
                  "owner": props.userData.owner,
                  "status": props.userData.status,
                  "permissions": props.userData.permissions,
                  "phoneNumber": checkDataExistsOrNot(countryCode) && checkDataExistsOrNot(mobileNumber) ? countryCode+mobileNumber : "",
                  "countryShortCode": checkDataExistsOrNot(countryFlag) && checkDataExistsOrNot(mobileNumber) ? countryFlag : ""
                }
                AuthClient.updatePortalUser(userPayload).then((response) => {
                    if (response.status === 200 || response.status === 201){
                        let userDetails = JSON.parse(sessionStorage.userDetails);
                        userDetails.user.phoneNumber = phoneNumber;
                        sessionStorage.setItem("userDetails",JSON.stringify(userDetails));
                        alertPayload["receivers"] = receiverData;
                        alertPayload["emailAlert"] = getEmailAlertVal(receiverData);
                        alertPayload["smsAlert"] = getSmsAlertVal(receiverData);
                        let json = setPublishData();
                        JobSchedulerClient.updateAlert(alertPayload).then((res) => {
                          if(res.status === 200 || res.status === 204 || res.status === 415){
                            enqueueSnackbar("Successfully subscribed to SMS Alert");
                            if (checkForReceivers(receiverData) && props.data.alertType !== "CAMERA_MODULE_ALERT") {
                              MqttEventHandler.publish(PubSubChannels.pubsub_channels.GENERIC_PUBLISH_CHANNEL.replace("MAC",sessionStorage.tenantId), JSON.stringify(json), {qos: 2, retain: false}, function() {});
                            }
                          }else {
                            props.setToggleToPreviousState();
                            enqueueSnackbar("Failed to Subscribe to SMS Alert");
                          }
                        });
                    }else {
                        props.setToggleToPreviousState();
                        enqueueSnackbar("Failed to update User");
                    }
                    props.closeDialog();
                });
            }else {
                 let receivers = [];
                 alreadySaved = false;
                 _.forEach(props.data.receivers, (receiver) => {
                  if (receiver.email === JSON.parse(sessionStorage.userDetails).user.email){
                    receiver["phoneNumber"] = JSON.parse(sessionStorage.userDetails).user.phoneNumber;
                    alreadySaved = true;

                  }
                  receivers.push(receiver);
                })
                if (!alreadySaved){
                  receivers.push({
                    "email": null,
                    "phoneNumber": JSON.parse(sessionStorage.userDetails).user.phoneNumber,
                  });
                }
                alertPayload["receivers"] = receivers;
                alertPayload["emailAlert"] = getEmailAlertVal(receivers);
                alertPayload["smsAlert"] = getSmsAlertVal(receivers);
                let json = setPublishData();
                JobSchedulerClient.updateAlert(alertPayload).then((res) => {
                  if(res.status === 200 || res.status === 204 || res.status === 415){
                    enqueueSnackbar("Successfully Subscribed to SMS Alert");
                    if (checkForReceivers(receivers) && props.data.alertType !== "CAMERA_MODULE_ALERT") {
                      MqttEventHandler.publish(PubSubChannels.pubsub_channels.GENERIC_PUBLISH_CHANNEL.replace("MAC",sessionStorage.tenantId), JSON.stringify(json), {qos: 2, retain: false}, function() {});
                    }
                  }else {
                    props.setToggleToPreviousState();
                    enqueueSnackbar("Failed to Subscribe to SMS Alert");
                  }
                  props.closeDialog();
                });
            }
        }
    }

    const unsubscribeToSmsAlerts = () => {
        if(!submitted){
            setSubmitted(true);
            let receivers = [];
            _.forEach(props.data.receivers, (receiver) => {  
              if (receiver.email !== null && receiver.email === JSON.parse(sessionStorage.userDetails).user.email){
                receiver["phoneNumber"] = null;            
              }
              if (JSON.parse(sessionStorage.userDetails).user.phoneNumber !== receiver["phoneNumber"])
                receivers.push(receiver);
            })

            let payload = {
              id : props.data.id,
              alertType : props.data.alertType,
              alertName : props.data.alertName,
              waitingTime : props.data.waitingTime,
              thresholdType : props.data.thresholdType,
              threshold : props.data.threshold,
              receivers : receivers,
              emailAlert : props.data.emailAlert,
              smsAlert : props.sendSmsAlert,
              scopeId: sessionStorage.tenantId,
              userId: props.data.userId
            }
            payload["emailAlert"] = getEmailAlertVal(receivers);
            payload["smsAlert"] = getSmsAlertVal(receivers);
            let json = setPublishData();
            JobSchedulerClient.updateAlert(payload).then((res) => {
              if(res.status === 200 || res.status === 204 || res.status === 415){
                enqueueSnackbar("Alert successfully updated");
                if (checkForReceivers(receivers) && props.data.alertType !== "CAMERA_MODULE_ALERT") {
                  MqttEventHandler.publish(PubSubChannels.pubsub_channels.GENERIC_PUBLISH_CHANNEL.replace("MAC",sessionStorage.tenantId), JSON.stringify(json), {qos: 2, retain: false}, function() {});
                }
              }else {
                props.setToggleToPreviousState();
                enqueueSnackbar("Failed to update alert");
              }
              props.closeDialog();
            });
        }
    }

    const setPublishData = () => {
        if (props.data.alertType !== "CAMERA_MODULE_ALERT") {
            let json = {};
            let type = props.dialog === "SubscribeToSmsAlert" ? "_C" : "_D";
            let alertType = props.data.alertType;
            let alert = alertType === "HIGH_CPU" ? "CP" : alertType === "HIGH_MEMORY_USAGE" ? "MM" : alertType === "HIGH_TEMP" ? "TEMP" : alertType === "HIGH_CO2" ? "CO2" : alertType === "HIGH_NH3" ? "NH3" : alertType === "HIGH_NO2" ? "NO2" : alertType === "HIGH_CO" ? "CO" : alertType === "HIGH_CO2_CTN" ? "CO2" : alertType === "HIGH_RSTC" ? "RSTC" : alertType === "HIGH_TVOC" ? "TVOC" : "camera-alert";
            let thresholdType = props.data.thresholdType === "greater_than" ? "_H" : "_L";
            let threshold = "_" + props.data.threshold;
            if (alertType === "HIGH_CPU" || alertType === "HIGH_MEMORY_USAGE") {
              json = {
                "sys" : alert + thresholdType + threshold + type
              }
            }else if (alertType === "HIGH_TEMP" || alertType === "HIGH_CO2" ) {
              json = {
                "co2_sensor" : alert + thresholdType + threshold + type
              }
            } else if (alertType === "HIGH_NH3" || alertType === "HIGH_NO2" || alertType === "HIGH_CO") {
              json = {
                "air_quality_5_sensor" : alert + thresholdType + threshold + type
              }
            } else if (alertType === "HIGH_CO2_CTN" || alertType === "HIGH_RSTC" || alertType === "HIGH_TVOC") {
              json = {
                "air_quality_2_sensor" : alert + thresholdType + threshold + type
              }
            }
            return json;
        }
    }

    const handleOnChange = (value) => {
        setCountryCode(value);
        document.getElementById("mobileField").focus();
    }

    const checkIsValidToSubmit = () => {
        if(!props.mobileNumberExists) {
            if(checkDataExistsOrNot(mobileNumber) || checkDataExistsOrNot(countryCode)) {
                return checkDataExistsOrNot(countryCode) ? checkDataExistsOrNot(mobileNumber) ? !validMobileNumber(mobileNumber) : true : true;
            }else {
                return true;
            }
        } else {
            return false;
        }
    }

    const validMobileNumber = (number) => {
        if (checkDataExistsOrNot(number)) {
            if (number.match("^[0-9]+$") && (number.length >= 7 && number.length <= 15)) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const getEmailAlertVal = (receivers) => {
        if(receivers.length === 0){
            return false;
        }
        return _.some(receivers, (receiver) => {
            if(receiver["email"] !== null){
                return true;
            }
        return false;
        })
    }

    const getSmsAlertVal = (receivers) => {
        if(receivers.length === 0){
            return false;
        }
        return _.some(receivers, (receiver) => {
            if(receiver["phoneNumber"] !== null){
                return true;
            }
        return false;
        })
    }

    const checkForReceivers = (receivers) => {
        if(receivers.length === 0){
            return true;
        }else{
            return receivers.length === 1 ? props.showSubscribeDialog ? !checkDataExistsOrNot(receivers[0]["email"]) : false : false;
        }
    }

    return (
        <div>
            <Dialog
                fullWidth={true}
                fullScreen={fullScreen}
                open={props.dialog === "SubscribeToSmsAlert" ? props.showSubscribeDialog : props.showUnsubscribeDialog}
                onClose={() => {props.closeDialog();props.setToggleToPreviousState()}}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                    <Box sx={{flex: '95'}}>{props.title}</Box>
                    <Box sx={{flex: '5', marginTop: '6px'}}>
                        <Close onClick={()=>{props.closeDialog();props.setToggleToPreviousState()}} sx={{cursor:"pointer"}}/>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        { props.showSubscribeDialog ?
                            !props.mobileNumberExists ?
                                <div>
                                    <div>Please provide mobile number to subscribe to SMS alerts</div>
                                    <Box sx={{position:"relative",marginTop:"10px"}}>
                                        <div style={{height:'26px',width:'62px',position: 'absolute',zIndex: '10',marginTop: '29px',marginLeft: '39px'}}></div>
                                        <TextField
                                            id="mobileField"
                                            label="Mobile Number"
                                            variant="standard"
                                            sx={{width: '60%', margin: '10px 0px', position: 'relative'}}
                                            value={mobileNumber}
                                            helperText={mobileNumber === "" ||  mobileNumber ? !mobileNumber.match("^[0-9]+$") ? "Mobile Number is required." : null : null}
                                            InputLabelProps = {{shrink:true}}
                                            InputProps={{
                                              startAdornment: (
                                                <InputAdornment position="start">
                                                  <PhoneInput
                                                      international
                                                      defaultCountry={checkDataExistsOrNot(countryFlag) ? countryFlag : "US"}
                                                      onCountryChange = {(val) => {
                                                        setCountryFlag(val);
                                                      }}
                                                      countryCallingCodeEditable={false}
                                                      placeholder="Country Code"
                                                      value={countryCode}
                                                      onChange={(value) => handleOnChange(value)}
                                                      style={{ width: '100px' }}
                                                  />
                                                </InputAdornment>
                                              ),
                                              inputProps: {
                                                maxLength: 15,
                                              },
                                            }}
                                            onChange={(event) => {
                                              let number = event.target.value;
                                              setMobileNumber(number);
                                            }}
                                        />
                                    </Box>
                                </div>
                            :
                                <>Are you sure you want to Subscribe to {props.data.alertName} SMS Alert?</>
                        :
                            <>Are you sure you want to Unsubscribe to {props.data.alertName} SMS Alert?</>
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{display:'flex', justifyContent:"end", position:"relative", height:"40px"}}>
                    <>
                        <Button onClick={() => {props.closeDialog();props.setToggleToPreviousState()}}>cancel</Button>
                        <Button disabled={props.dialog === "SubscribeToSmsAlert" ? checkIsValidToSubmit() : false} onClick={() => {props.dialog === "SubscribeToSmsAlert" ?  subscribeToSmsAlerts() : unsubscribeToSmsAlerts()}}>
                            Yes
                        </Button>
                    </>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default SubscribeOrUnsubscribeToSmsAlert;

import { Close } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery, useTheme } from '@mui/material';
import checkDataExistsOrNot from 'helper-components/checkDataExistsOrNot';
import * as React from 'react';


export default function HotSpotToggleDialogAlert(props) {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [primaryThemeColor, setPrimaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7");
    const [secondaryThemeColor, setSecondaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#fff");



    return (
        <>
            <Dialog
                fullWidth={true}
                fullScreen={fullScreen}
                open={props.openDialog}
                onClose={() => props.close()}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                    <Box sx={{flex: '95'}}>Gateway HotSpot Toggle</Box>
                    <Box sx={{flex: '5', marginTop: '6px'}}>
                        <Close onClick={()=>props.close()} sx={{cursor:"pointer"}}/>
                    </Box>
                </DialogTitle>
                <DialogContent >
                    <DialogContentText>
                        This process will take up to 1 minute. Are you sure you want to <b>{props.toggleState}</b> Hotspot status
                        {
                            props.fromDevicePage ?
                                <></>
                            :
                                <span style={{display: props.hotSpotFlag ? "inline" : "none" }}> and <b>Register</b> the gateway.</span>
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{display:'flex', justifyContent:"end", position:"relative", height:"40px"}}>
                    <>
                        <Button onClick={() => props.close()}>cancel</Button>
                        <Button sx={{color:primaryThemeColor}} onClick={() => {props.toggleHotSpotStatus(); props.closeDialog();}} autoFocus>
                            ok
                        </Button>
                    </>
                </DialogActions>
            </Dialog>
        </>
    )
}

import { Add, Close, Delete, Edit, FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage, Refresh, Search } from '@mui/icons-material';
import { Box, Button, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Tooltip, Switch } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import AuthClient from "clients/AuthClient";
import IotServiceClient from "clients/IotServiceClient";
import JobSchedulerClient from "clients/JobSchedulerClient";
import MqttEventHandler from 'components/Mqtt/MqttEventHandler';
import checkDataExistsOrNot from 'helper-components/checkDataExistsOrNot.js';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import PubSubChannels from 'pubsub/PubSubChannels';
import React from 'react';
import "stylesheet/common.css";
import 'stylesheet/DashboardGatewayPage.css';
import 'stylesheet/DashBoardUsersPage.css';
import paginationStyle from "stylesheet/pagination.module.css";
import ConfigureAlert from './ConfigureAlert.js';
import SubscribeOrUnsubscribeToEmailAlert from './SubscribeOrUnsubscribeToEmailAlert.js';
import SubscribeOrUnsubscribeToSmsAlert from './SubscribeOrUnsubscribeToSmsAlert.js';

function TablePaginationActions(props) {
  const {count, page, rowsPerPage, onPageChange} = props;

  function handleFirstPageButtonClick(event) {
    onPageChange(event, 0);
  }

  function handleBackButtonClick(event) {
    onPageChange(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onPageChange(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className="navigationIcons">
      <FirstPage
        onClick={() => {
          if (page > 0) handleFirstPageButtonClick();
        }}
        sx={{
          color: page === 0 ? 'rgba(0,0,0,0.26)' : 'black',
          cursor: page === 0 ? 'auto' : 'pointer',
          marginRight: '8px'
        }}
      />
      <KeyboardArrowLeft
        onClick={() => {
          if (page > 0) handleBackButtonClick();
        }}
        sx={{
          color: page === 0 ? 'rgba(0,0,0,0.26)' : 'black',
          cursor: page === 0 ? 'auto' : 'pointer',
          marginRight: '8px'
        }}
      />
      <KeyboardArrowRight
        onClick={() => {
          if (page !== Math.ceil(count / rowsPerPage) - 1)
            handleNextButtonClick();
        }}
        sx={{
          color:
            page !== Math.ceil(count / rowsPerPage) - 1 && count > 0
              ? 'black'
              : 'rgba(0,0,0,0.26)',
          cursor:
            page !== Math.ceil(count / rowsPerPage) - 1 && count > 0 ? 'pointer' : 'auto',
            marginRight: '8px'
        }}
      />
      <LastPage
        onClick={() => {
          if (page !== Math.ceil(count / rowsPerPage) - 1)
            handleLastPageButtonClick();
        }}
        sx={{
          color:
            page !== Math.ceil(count / rowsPerPage) - 1 && count > 0
              ? 'black'
              : 'rgba(0,0,0,0.26)',
          cursor:
            page !== Math.ceil(count / rowsPerPage) - 1 && count > 0 ? 'pointer' : 'auto',
        }}
      />
    </div>
  );
}

 const showSubscribeOrUnsubscribeIcons = () => {
    return ((sessionStorage.userDetails && JSON.parse(sessionStorage.userDetails).user.scopeId === "66c8765e927a13321c085d4b") || JSON.parse(sessionStorage.userDetails).user.email === "support@cariboutech.com") && sessionStorage.tenantId === "66c8765e927a13321c085d4b" ? true : false;
 }


function EnhancedTableHead(props) {
  const {onSelectAllClick, order, orderBy, onRequestSort, generalUser} = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const [changeTextColor,setChangeTextColor] = React.useState('');
  const headCells = [
    {
      id: 'alertName',
      numeric: false,
      disablePadding: false,
      label: 'Title',
      width: '120px'
    },
    {
      id: 'alertType',
      numeric: false,
      disablePadding: false,
      label: 'Type',
      width: '150px'
    },
    {
      id: 'threshold',
      numeric: false,
      disablePadding: false,
      label: 'Threshold',
      width: '90px'
    },
    {
      id: 'waitingTime',
      numeric: false,
      disablePadding: false,
      label: 'Waiting Time',
      width: '120px'
    },
  ];

  const headCell = [
    {
      id: 'alertName',
      numeric: false,
      disablePadding: false,
      label: 'Title',
      width: '120px'
    },
    {
      id: 'alertType',
      numeric: false,
      disablePadding: false,
      label: 'Type',
      width: '150px'
    },
    {
      id: 'threshold',
      numeric: false,
      disablePadding: false,
      label: 'Threshold',
      width: '90px'
    },
    {
      id: 'waitingTime',
      numeric: false,
      disablePadding: false,
      label: 'Waiting Time',
      width: '120px'
    },
    {
      id: 'Email',
      numeric: false,
      disablePadding: false,
      label: 'Email',
      width: '80px'
    },
    {
      id: 'SMS',
      numeric: false,
      disablePadding: false,
      label: 'SMS',
      width: '80px'
    },
  ];

  const [clickCount, setClickCount] = React.useState(1);


  const selectAll = (event) => {
    setClickCount(clickCount + 1);
    onSelectAllClick(clickCount);
  };


  return (
    <TableHead>
      <TableRow>
         {showSubscribeOrUnsubscribeIcons() ? (
            headCell.map((cell) => (
              <TableCell key={cell.id} align="left">
                {cell.label !== '' ? (
                  <TableSortLabel
                    active={orderBy === cell.id}
                    direction={orderBy === cell.id ? order : 'asc'}
                    onClick={createSortHandler(cell.id)}
                    sx={{
                      width: cell.width,
                      overflowWrap: 'break-word',
                      color: orderBy !== cell.id ? '#0000008A !important' : '#000000DE !important',
                    }}
                  >
                    {cell.label}
                    {orderBy === cell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                ) : (
                  <Box
                    sx={{
                      width: cell.width,
                      overflowWrap: 'break-word',
                      color: changeTextColor !== '' ? '#0000008A !important' : '#000000DE !important',
                    }}
                  >
                    {cell.label}
                  </Box>
                )}
              </TableCell>
            ))
         ) : (
            headCells.map((headCell) => (
              <TableCell key={headCell.id} align="left">
                {headCell.label !== '' ? (
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                    sx={{
                      width: headCell.width,
                      overflowWrap: 'break-word',
                      color: orderBy !== headCell.id ? '#0000008A !important' : '#000000DE !important',
                    }}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                ) : (
                  <Box
                    sx={{
                      width: headCell.width,
                      overflowWrap: 'break-word',
                      color: changeTextColor !== '' ? '#0000008A !important' : '#000000DE !important',
                    }}
                  >
                    {headCell.label}
                  </Box>
                )}
              </TableCell>
            ))
         )}
      </TableRow>
    </TableHead>
  );
}

export default function Alerts() {
  const [SearchBar, setSearchBar] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(sessionStorage.dashboardAlertRPP ? Number(sessionStorage.dashboardAlertRPP) : 10);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(false);
  const [editMode, setEdiitMode] = React.useState(false);
  const [showEditIcon, setShowEditIcon] = React.useState(true);
  const [searchText, setSearchText] = React.useState('');
  const [checkAll, setCheckAll] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [alertsListData,setAlertListData] = React.useState([]);
  const [PortalUsersData, setPortalUserdData] = React.useState([]);
  const alertList = [];
  const [primaryThemeColor, setPrimaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7")
  const [secondaryThemeColor, setSecondaryThemeColor] = React.useState(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#fff")
  const [refresh,setRefresh] = React.useState(true)
  const { enqueueSnackbar } = useSnackbar();
  const [portalUserData, setPortalUserData] = React.useState([]);
  const[selectedUsers, setSelectedUsers] = React.useState([]);
  const [generalUser, setGeneralUser] = React.useState(false);
  const [subscribeToEmailAlert, setSubscribeToEmailAlert] = React.useState(false);
  const [unsubscribeToEmailAlert, setUnsubscribeToEmailAlert] = React.useState(false);
  const [emailAlertData, setEmailAlertData] = React.useState();
  const [sendEmailAlert, setSendEmailAlert] = React.useState(false);
  const [subscribeToSmsAlert, setSubscribeToSmsAlert] = React.useState(false);
  const [unsubscribeToSmsAlert, setUnsubscribeToSmsAlert] = React.useState(false);
  const [smsAlertData, setSmsAlertData] = React.useState();
  const [sendSmsAlert, setSendSmsAlert] = React.useState(false);
  const [generalUserData, setGeneralUserData] = React.useState([]);
  const [isMobileNumberExists, setIsMobileNumberExists] = React.useState(false);
  const [showAlertForUsers, setShowAlertForUsers] = React.useState(['support@cariboutech.com','enterprisetenantcaribou@yopmail.com']);



  const checkSmsOrEmailAlertExist = (alertsDataPayload, checkValue) => {
    let flag = false;
    _.forEach(alertsDataPayload, (data) => {
        if (checkValue === "email" && data.email === JSON.parse(sessionStorage.userDetails).user.email){
          flag = true;
        }
        if (checkValue === "sms" && data.phoneNumber !== null && data.phoneNumber === JSON.parse(sessionStorage.userDetails).user.phoneNumber){
          flag = true;
        }
    })

    return flag;
  }
  const fetchAlerts = (isMqttEvent) => {
    let res = [];
    let isRefreshingWithFilter = searchText.length > 0;
    AuthClient.getPortalUsers(sessionStorage.tenantId, "alertsPage").then((portalUserData) => {
      if (portalUserData.hasOwnProperty("message")){
        portalUserData = [];
      }
      _.forEach(portalUserData,(element)=>{
        element.mergedName = element.mergedName = element.firstName ? (element.lastName ? element.firstName+ " " +element.lastName : element.firstName) : (element.lastName ? element.lastName : "No Name");
        element.email = element.email ? element.email : 'No-email';
      })

      portalUserData = portalUserData.sort((first,second) => first.mergedName.localeCompare(second.mergedName));
      setPortalUserdData(portalUserData);
    })

    JobSchedulerClient.getAlerts(sessionStorage.tenantId, "alertsPage").then((data) => {
      res = data;
      let filteredData = [];
      let generalUserAlerts = [];
      let isGeneralUser = sessionStorage.userDetails && JSON.parse(sessionStorage.userDetails).user.scopeId === "66c8765e927a13321c085d4b" ||
                            (sessionStorage.userDetails && JSON.parse(sessionStorage.userDetails).user.email === "support@cariboutech.com" && sessionStorage.tenantId === "66c8765e927a13321c085d4b") ? true : false;
      if (!res.hasOwnProperty("status")) {
          _.forEach(res, (element) => {
            if(isGeneralUser){
              element.alertTypeName = element.alertType === "HIGH_CPU" ? "CPU Alert" : element.alertType === "HIGH_MEMORY_USAGE" ? "Memory Usage Alert" : element.alertType === "HIGH_CO2" ? "CO2 Alert" : element.alertType === "HIGH_TEMP" ? "Temperature Alert" : element.alertType === "HIGH_NO2" ? "NO2 Alert" : element.alertType === "HIGH_NH3" ? "NH3 Alert" : element.alertType === "HIGH_CO" ? "CO Alert" : element.alertType === "HIGH_CO2_CTN" ? "CO2 Alert(air quality sensor 2)" : element.alertType === "HIGH_RSTC" ? "RSTC Alert" : element.alertType === "HIGH_TVOC" ? "TVOC Alert" : "Camera Removal Alert";
              element.alert = element.alertType !== "CAMERA_MODULE_ALERT" ? element.thresholdType === "greater_than" ? "High " + element.alertTypeName : "Low " + element.alertTypeName : "Camera Removal Alert";
              element.receiversLength = element.receivers.length > 1 ? element.receivers.length + " Users" : element.receivers.length + " User" ;
              element.waitingTimeTextValue = element.waitingTime + " seconds";
              element.emailAlert = element.receivers.length === 0 ? false : checkSmsOrEmailAlertExist(element.receivers, "email");
              element.smsAlert = element.receivers.length === 0 ? false : checkSmsOrEmailAlertExist(element.receivers, "sms");
              generalUserAlerts.push(element);
            }else{
              element.alertTypeName = element.alertType === "HIGH_CPU" ? "CPU Alert" : element.alertType === "HIGH_MEMORY_USAGE" ? "Memory Usage Alert" : element.alertType === "HIGH_CO2" ? "CO2 Alert" : element.alertType === "HIGH_TEMP" ? "Temperature Alert" : element.alertType === "HIGH_NO2" ? "NO2 Alert" : element.alertType === "HIGH_NH3" ? "NH3 Alert" : element.alertType === "HIGH_CO" ? "CO Alert" : element.alertType === "HIGH_CO2_CTN" ? "CO2 Alert(air quality sensor 2)" : element.alertType === "HIGH_RSTC" ? "RSTC Alert" : element.alertType === "HIGH_TVOC" ? "TVOC Alert" : "Camera Removal Alert";
              element.alert = element.alertType !== "CAMERA_MODULE_ALERT" ? element.thresholdType === "greater_than" ? "High " + element.alertTypeName : "Low " + element.alertTypeName : "Camera Removal Alert";
              element.receiversLength = element.receivers.length > 1 ? element.receivers.length + " Users" : element.receivers.length + " User" ;
              element.waitingTimeTextValue = element.waitingTime + " seconds";
              filteredData.push(element);
            }
          });
          window.AlertsRawData = isGeneralUser ? generalUserAlerts : filteredData;
          if(!isRefreshingWithFilter) {
              setRows(isGeneralUser ? generalUserAlerts : filteredData);
              resetPaginationData([]);
            } else {
              let text = document.getElementById("searchText") ? document.getElementById("searchText").value : null;
              handleTextCange(text);
            }
      } else {
          setRows(isGeneralUser ? generalUserAlerts : filteredData);
          resetPaginationData([]);
      }
    });
  }

  const handleEvent = (data) => {
    let latestAlertData = [];
    let message = data.message ? data.message : "";
    let payload = data.payload ? Array.isArray(data.payload) ? data.payload[0] : data.payload : "";
    let refreshUi = false;
    if (message === "deleteAlert"){
      _.forEach(window.AlertsRawData, (val)=>{
        if (val.id !== payload.id) {
          latestAlertData.push(val);
        }
      })
      refreshUi = true;
    }else if (message === "updateAlert"){
      if (payload.alertType !== "RFID"){
        _.forEach(window.AlertsRawData, (element)=>{
          if (element.id === payload.id) {
            element.alertTypeName = element.alertType === "HIGH_CPU" ? "CPU Alert" : element.alertType === "HIGH_MEMORY_USAGE" ? "Memory Usage Alert" : element.alertType === "HIGH_CO2" ? "CO2 Alert" : element.alertType === "HIGH_TEMP" ? "Temperature Alert" : element.alertType === "HIGH_NO2" ? "NO2 Alert" : element.alertType === "HIGH_NH3" ? "NH3 Alert" : element.alertType === "HIGH_CO" ? "CO Alert" : element.alertType === "HIGH_CO2_CTN" ? "CO2 Alert(air quality sensor 2)" : element.alertType === "HIGH_RSTC" ? "RSTC Alert" : element.alertType === "HIGH_TVOC" ? "TVOC Alert" : "Camera Removal Alert";
            element.alert = element.alertType !== "CAMERA_MODULE_ALERT" ? element.thresholdType === "greater_than" ? "High " + element.alertTypeName : "Low " + element.alertTypeName : "Camera Removal Alert";
            element.intervalTime = payload.alertType !== 'UNLOCK' && payload.alertType !== 'RFID' ? payload.intervalTime : payload.alertType === 'RFID' ? Number(payload.rfidScheduleTime) : 0;
            element.receivers = payload.receivers;
            element.receiversLength = payload.receivers.length > 1 ? payload.receivers.length + " Users" : payload.receivers.length + " User" ;
            element.waitingTimeTextValue = element.waitingTime + " seconds";
            if (typeof payload.rfidScheduleTime !== 'undefined' && payload.rfidScheduleTime !== null){
              element.intervalTime = payload.rfidScheduleTime;
            }
          }
          latestAlertData.push(element);
        })
        refreshUi = true;
      }else {
        enqueueSnackbar("Refreshing Alerts ...");
        fetchAlerts(false);
      }
    }else if (message === "createAlert") {
      _.forEach(window.AlertsRawData, (element)=>{
        latestAlertData.push(element);
      })
      payload.alertTypeName = payload.alertType === "HIGH_CPU" ? "CPU Alert" : payload.alertType === "HIGH_MEMORY_USAGE" ? "Memory Usage Alert" : payload.alertType === "HIGH_CO2" ? "CO2 Alert" : payload.alertType === "HIGH_TEMP" ? "Temperature Alert" : payload.alertType === "HIGH_NO2" ? "NO2 Alert" : payload.alertType === "HIGH_NH3" ? "NH3 Alert" : payload.alertType === "HIGH_CO" ? "CO Alert" : payload.alertType === "HIGH_CO2_CTN" ? "CO2 Alert(air quality sensor 2)" : payload.alertType === "HIGH_RSTC" ? "RSTC Alert" : payload.alertType === "HIGH_TVOC" ? "TVOC Alert" : "Camera Removal Alert";
      payload.alert = payload.alertType !== "CAMERA_MODULE_ALERT" ? payload.thresholdType === "greater_than" ? "High " + payload.alertTypeName : "Low " + payload.alertTypeName : "Camera Removal Alert";
      payload.intervalTime = payload.alertType !== 'UNLOCK' && payload.alertType !== 'RFID' ? payload.intervalTime : payload.alertType === 'RFID' ? Number(payload.rfidScheduleTime) : 0;
      payload.receiversLength = payload.receivers.length > 1 ? payload.receivers.length + " Users" : payload.receivers.length + " User" ;
      payload.waitingTimeTextValue = payload.waitingTime + " seconds";
      if (typeof payload.rfidScheduleTime !== 'undefined' && payload.rfidScheduleTime !== null){
        payload.intervalTime = payload.rfidScheduleTime;
      }
      latestAlertData.push(payload);
      refreshUi = true;
    }else if (message === "userRemovedFromPortal") {
      enqueueSnackbar("Refreshing Alerts ...");
      fetchAlerts(false);
    }

    if (refreshUi){
      enqueueSnackbar("Refreshing Alerts ...");
      window.AlertsRawData = latestAlertData;
      let text = document.getElementById("searchText") ? document.getElementById("searchText").value : null;
      handleTextCange(text);
    }
  }

  const onAlertEvent = (data) => {
    if(true){
        fetchAlerts(false);
    } else {
        data = data ? JSON.parse(data.toString()) : "" ;
        if (data.hasOwnProperty("message")){
          handleEvent(data);
        }
    }
  }

  const subscribeMqttChannels = () => {
    window.alertsMqttEventHandler = MqttEventHandler.subscribe(PubSubChannels.pubsub_channels.ALERTS.replace("scopeId",sessionStorage.tenantId),"alertController",onAlertEvent)
  }

  React.useEffect(() => {
    enqueueSnackbar("Fetching Alerts ...");
    setTimeout(() => {
      setPrimaryThemeColor(checkDataExistsOrNot(sessionStorage.tenantPrimaryThemeColor) ? sessionStorage.tenantPrimaryThemeColor : "#4190c7")
      setSecondaryThemeColor(checkDataExistsOrNot(sessionStorage.tenantSecondaryThemeColor) ? sessionStorage.tenantSecondaryThemeColor : "#ffffff")
    }, 1000);
    fetchAlerts();
    window.oldPreservedAlertsPageNumber = 0;
    subscribeMqttChannels();
     if (sessionStorage.userDetails && JSON.parse(sessionStorage.userDetails).user.scopeId === "66c8765e927a13321c085d4b") {
       setGeneralUser(true);
     }
    return () =>{
      if (window.alertsMqttEventHandler){
        window.alertsMqttEventHandler.unsubscribe();
      }
      AuthClient.abortSignal("alertsPage");
      JobSchedulerClient.abortSignal("alertsPage")
    }
  }, []);

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el && el !== null && el !== "" ? el : el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPageNumber(newPage);
    window.oldPreservedAlertsPageNumber = newPage;
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleSelectAllClick = (checkboxClickCount) => {
    if (checkboxClickCount % 2 !== 0) {
      setCheckAll(true);
      // eslint-disable-next-line array-callback-return
      const newSelecteds = rows.filter((n) => {
        if (
          n.Status !== 'INACTIVE' &&
          typeof n.id !== 'undefined' &&
          n.id !== null
        ) {
          return n;
        }
      });
      setSelected([]);
      setSelected(newSelecteds);
      return;
    }
    setCheckAll(false);
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const [pageNumber, setPageNumber] = React.useState(0);

  const handleChange = (event) => {
    setPageNumber(event.target.value);
    setPage(event.target.value);
    window.oldPreservedAlertsPageNumber = event.target.value;
  };

  const handleRowPerPage = (event) => {
    let rowPerPageValue = event.target.value;
    if (rows.length > 0) {
      let pageNumberCount = Math.ceil((rows.length/rowPerPageValue)) - 1;
      if (window.oldPreservedAlertsPageNumber > pageNumberCount) {
        setPage(pageNumberCount);
        setPageNumber(pageNumberCount);
        window.oldPreservedAlertsPageNumber = pageNumberCount;
      }else {
        setPage(window.oldPreservedAlertsPageNumber);
        setPageNumber(window.oldPreservedAlertsPageNumber);
      }
    }
    sessionStorage.dashboardAlertRPP = rowPerPageValue;
    setRowsPerPage(rowPerPageValue);
  };

  const checkSearchTextExist = (data, key,  text) => {
      let value = data[key];
      return value !== null && typeof value !== 'undefined' && value !== "" ? value.toString().toLowerCase().replaceAll(" ","").indexOf(text) !== -1 : false;
    };

  const handleTextCange = (textValue) => {
    textValue = document.getElementById("searchText") ? document.getElementById("searchText").value : "";
    setSearchText(textValue);
    let sortedDevices = [];
    let searchText = textValue;
    if (searchText !== "" && searchText !== undefined && searchText !==  null){
        let text = searchText.toLowerCase().replaceAll(" ","");
        _.forEach(window.AlertsRawData,(value)=>{
          if (checkSearchTextExist(value, "alertType", text) || checkSearchTextExist(value, "alertName", text) || checkSearchTextExist(value, "threshold", text) || checkSearchTextExist(value, "waitingTime", text)){
            sortedDevices.push(value);
          }
        })
        sortedDevices = _.uniq(sortedDevices);
        setRows(sortedDevices);
    } else {
        setRows(window.AlertsRawData);
    }
    resetPaginationData(sortedDevices);
  };

  const resetPaginationData = (data)=> {
    if (window.oldPreservedAlertsPageNumber) {
      if (data.length > 0) {
        let pageNumberCount = Math.ceil((data.length/rowsPerPage)) - 1;
        if (window.oldPreservedAlertsPageNumber > pageNumberCount) {
          setPage(pageNumberCount);
          setPageNumber(pageNumberCount);
          window.oldPreservedAlertsPageNumber = pageNumberCount;
        }
      }else {
        let pageNumberCount = Math.ceil((window.AlertsRawData.length/rowsPerPage)) - 1;
        if (window.oldPreservedAlertsPageNumber > pageNumberCount){
          setPage(pageNumberCount);
          setPageNumber(pageNumberCount);
          window.oldPreservedAlertsPageNumber = pageNumberCount;
        }else if (window.oldPreservedAlertsPageNumber <= pageNumberCount && pageNumberCount > 0) {
          setPage(window.oldPreservedAlertsPageNumber);
          setPageNumber(window.oldPreservedAlertsPageNumber);
        }else {
          setPage(0);
          setPageNumber(0);
          window.oldPreservedAlertsPageNumber = 0;
        }
      }
    }else {
      setPageNumber(0);
      setPage(0);
      window.oldPreservedAlertsPageNumber = 0;
    }
  }

  const clearSearchField = () => {
    setSearchText('');
    document.getElementById('searchText').value = '';
    setSearchBar(false);
    setRows(window.AlertsRawData);
  }

const rowPerPageLabel = (
          <div className={paginationStyle.rowPerPageLabelDiv}>
            <Box className={paginationStyle.page}>
              <span style={{marginRight: '32px'}}>Page</span>
            </Box>
            <Box className={paginationStyle.pageSelectBox}>
              <Select value={pageNumber} onChange={handleChange}>
                {
                  Math.ceil(rows.length / rowsPerPage) > 0 ?
                    // eslint-disable-next-line array-callback-return
                    Array(Math.ceil(rows.length / rowsPerPage))
                      .fill()
                      .map((_, index) => {
                        return (
                          <MenuItem value={index} sx={{minWidth: '64px'}}>
                            {index + 1}
                          </MenuItem>
                        );
                      })
                  :
                    <MenuItem value={0} sx={{minWidth: '64px'}}>
                      1
                    </MenuItem>
                }
              </Select>
            </Box>
            <Box className={paginationStyle.rowPerPageLabelDiv}>
              <span style={{marginRight: '32px'}}>Rows Per Page</span>
            </Box>
            <Box className={paginationStyle.rowPerPageSelectBox}>
              <Select value={rowsPerPage} onChange={handleRowPerPage}>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={15}>15</MenuItem>
              </Select>
            </Box>
          </div>
        );

  // Edit permission dialog & View Permission Dialog

  const [editUnlockAlert, setEditUnlockAlert] = React.useState(false);

  const [alertsData, setAlertsData] = React.useState([]);

  // DELETE PERMISSION DIALOG

  const handleCloseEditUnlockAlert = (value) => {
    setEditUnlockAlert(value);
  };

  // ADD ALERT

  const [createAlert, setCreateAlert] = React.useState(false);

  const handleCreateAlert = (value) => {
    setCreateAlert(value);
  };

  //DELETE ALERT

  const [deleteAlert, setDeleteAlert] = React.useState(false);
  const [deleteAlertData, setDeleteAlertData] = React.useState([]);

  const handleDeleteAlert = (value) => {
    setDeleteAlert(value);
  };

  const handleDeleteAlertDialog = (data) => {
    setDeleteAlertData(data);
    setDeleteAlert(true);
  };

  const handleRefresh = () => {
    setRefresh(false);
        setTimeout(() => {
          setRefresh(true);
    }, 1050);
    enqueueSnackbar("Refreshing Alerts ...")
    fetchAlerts();
  }

  const subscribeOrUnsubscribeEmailAlert = (event,data) => {
    if(data.emailAlert === true) {
      setUnsubscribeToEmailAlert(true);
      setSendEmailAlert(event.target.checked);
      setEmailAlertData(data);
      data.emailAlert = false;
    }else {
      setSubscribeToEmailAlert(true);
      setSendEmailAlert(event.target.checked);
      setEmailAlertData(data);
      data.emailAlert = true;
    }
  }

  const subscribeOrUnsubscribeSmsAlert = (event,data) => {
    if(data.smsAlert === true) {
      setUnsubscribeToSmsAlert(true);
      setSendSmsAlert(event.target.checked);
      setSmsAlertData(data);
      data.smsAlert = false;
    }else {
      setSubscribeToSmsAlert(true);
      setSendSmsAlert(event.target.checked);
      setSmsAlertData(data);
      let userData = [];
      if(JSON.parse(sessionStorage.userDetails).user.email === "support@cariboutech.com"){
        setGeneralUserData(JSON.parse(sessionStorage.userDetails).user);
        setIsMobileNumberExists(checkDataExistsOrNot(JSON.parse(sessionStorage.userDetails).user.phoneNumber));
      }else{
          _.forEach(PortalUsersData,(user)=>{
            if(JSON.parse(sessionStorage.userDetails).user.email === user.email){
              setGeneralUserData(user);
              setIsMobileNumberExists(checkDataExistsOrNot(JSON.parse(sessionStorage.userDetails).user.phoneNumber));
            }
          })
      }
      data.smsAlert = true;
    }
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '20px 20px 0px 24px',
          position: 'relative',
          background: 'rgb(244,250,254)'
        }}
      >
        <input
            id="searchText"
            type="text"
            onChange={handleTextCange}
            placeholder="Search.."
            autoFocus
            style={{ width: "692px", height: "55px", display : 'flex',
                gap: "0px",
                border: "1px 0px 0px 0px",
                borderWidth: "0px, 0px, 0px, 0px",
                borderStyle: "solid",
                borderColor: "var(--miscellaneous-alert-menu-action-sheet-separators, #8080808C)",
                textIndent: '50px',
                color: "#8080808C",
                fontSize: "16px",
                borderRadius: "10px"
            }}
        />
        <Search
            style={{
              top:'35px',left: '44px', fontSize:'26px',
              position: "absolute", color: '#8080808C',
            }}
        />
      </Box>
      <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            padding: '20px 0px 25px 24px',
          }}
      >
        <span style={{fontSize: '20px', marginRight: 'auto'}}>Alerts</span>
      </Box>
      <Box style={{height: "auto",margin: "0px 20px 86px 20px",borderRadius: "20px",border: "2px solid #fff",background:"#fff",boxShadow: "7px 12px 16px -11px rgba(12, 12, 13, 0.4)"}}>
        <TableContainer>
          <Table>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              check_all={checkAll}
              onSelectAllClick={(checked) => handleSelectAllClick(checked)}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              generalUser={generalUser}
            />
            <TableBody sx={{background: '#fff'}}>
              {stableSort(rows,getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                // eslint-disable-next-line array-callback-return
                .map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                       showSubscribeOrUnsubscribeIcons() ?
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            className={paginationStyle.tableBodyRow}
                            sx={{borderBottom:'1px solid #c1a6a6'}}
                          >
                            <TableCell>{row.alertName}</TableCell>
                            <TableCell>{row.alert}</TableCell>
                            <TableCell align="left">{row.threshold}</TableCell>
                            <TableCell align="left">{row.waitingTimeTextValue}</TableCell>
                            <TableCell align="left">
                              <Switch size='medium' sx={{marginLeft: '-11px'}} checked={row.emailAlert} onClick={(event) => {createAlert || editUnlockAlert ? event.stopPropagation() : subscribeOrUnsubscribeEmailAlert(event,row)}}/>
                            </TableCell>
                            <TableCell align="left">
                              <Switch size='medium' sx={{marginLeft: '-11px'}} checked={row.smsAlert} onClick={(event) => {createAlert || editUnlockAlert ? event.stopPropagation() : subscribeOrUnsubscribeSmsAlert(event,row)}}/>
                            </TableCell>
                            <TableCell align="left" sx={{margin: '0px'}}>
                              <Box className="usersActions cursorPointer">
                                <Box
                                  sx={{
                                    margin: '0px 32px 0px 19px',
                                  }}
                                >
                                  <Edit
                                    onClick={() => {
                                      let filteredUsers = [];
                                      let user = {};
                                      let allReceiversData = [];
                                      _.forEach(PortalUsersData,(allReceivers)=>{
                                         let users = {};
                                         users.id = allReceivers.id;
                                         users.name = allReceivers.mergedName;
                                         users.email = allReceivers.email;
                                         users.phoneNumber = allReceivers.phoneNumber;
                                         _.forEach(row.receivers, (val) => {
                                             if(val.email === allReceivers.email){
                                               filteredUsers.push(users);
                                             }
                                         })
                                         allReceiversData.push(users);
                                      })
                                      setPortalUserData(allReceiversData);
                                      setSelectedUsers(row.receivers);
                                      setAlertsData(row);
                                      setEditUnlockAlert(true);
                                    }}
                                    sx={{color:'#0000008A',fontSize: '20px',display: showAlertForUsers.includes(JSON.parse(sessionStorage.userDetails).user.email) ? 'block' : 'none'}}
                                  />
                                </Box>
                                <Box>
                                  <Delete
                                    className="dashboardDeleteIcon cursorPointer"
                                    onClick={() => handleDeleteAlertDialog(row)}
                                    sx={{color:'#0000008A',fontSize: '20px',display: showAlertForUsers.includes(JSON.parse(sessionStorage.userDetails).user.email) ? 'block' : 'none'}}
                                  />
                                </Box>
                              </Box>
                            </TableCell>
                          </TableRow>
                       :
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            className={paginationStyle.tableBodyRow}
                            sx={{borderBottom:'1px solid #c1a6a6'}}
                          >
                            <TableCell>{row.alertName}</TableCell>
                            <TableCell>{row.alert}</TableCell>
                            <TableCell align="left">{row.threshold}</TableCell>
                            <TableCell align="left">{row.waitingTimeTextValue}</TableCell>
                            <TableCell align="left" sx={{margin: '0px'}}>
                              <Box className="usersActions cursorPointer">
                                <Box
                                  sx={{
                                    margin: '0px 32px 0px 19px',
                                  }}
                                >
                                  <Edit
                                    onClick={() => {
                                      let filteredUsers = [];
                                      let user = {};
                                      let allReceiversData = [];
                                      _.forEach(PortalUsersData,(allReceivers)=>{
                                         let users = {};
                                         users.id = allReceivers.id;
                                         users.name = allReceivers.mergedName;
                                         users.email = allReceivers.email;
                                         users.phoneNumber = allReceivers.phoneNumber;
                                         _.forEach(row.receivers, (val) => {
                                             if(val.email === allReceivers.email){
                                               filteredUsers.push(users);
                                             }
                                         })
                                         allReceiversData.push(users);
                                      })
                                      setPortalUserData(allReceiversData);
                                      setSelectedUsers(filteredUsers);
                                      setAlertsData(row);
                                      setEditUnlockAlert(true);
                                    }}
                                    sx={{color:'#0000008A',fontSize: '20px',display: JSON.parse(sessionStorage.userDetails).user.permissions.includes("ALERTS_WRITE") ? 'block' : 'none'}}
                                  />
                                </Box>
                                <Box>
                                  <Delete
                                    className="dashboardDeleteIcon cursorPointer"
                                    onClick={() => handleDeleteAlertDialog(row)}
                                    sx={{color:'#0000008A',fontSize: '20px',display: JSON.parse(sessionStorage.userDetails).user.permissions.includes("ALERTS_WRITE") ? 'block' : 'none'}}
                                  />
                                </Box>
                              </Box>
                            </TableCell>
                          </TableRow>
                    );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{width: '100%', overflowX: 'hidden'}}
          labelRowsPerPage={rowPerPageLabel}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          editMode={editMode}
          page={pageNumber}
          SelectProps={{
            inputProps: {'aria-label': 'rows per page'},
            native: true,
          }}
          className="pagination"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Box>
      {
      showAlertForUsers.includes(JSON.parse(sessionStorage.userDetails).user.email) ||
        (JSON.parse(sessionStorage.userDetails).user.scopeId !== "66c8765e927a13321c085d4b" &&
          JSON.parse(sessionStorage.userDetails).user.permissions.includes("ALERTS_WRITE") ) ?
              <Button
                style={{
                  position: 'fixed',
                  bottom: '37px',
                  right: '40px',
                  height: '62px',
                  miWwidth: '55px',
                  maxWidth: '55px',
                  borderRadius: '50%',
                  backgroundColor: '#007AFF',
                }}
                onClick={() => {
                    let filteredUsers = [];
                    let user = null;
                    _.forEach(PortalUsersData,(allReceivers)=>{
                       if(allReceivers.mergedName !== 'undefined'){
                           user = {};
                           user.id = allReceivers.id;
                           user.name = allReceivers.mergedName;
                           user.email = allReceivers.email;
                           user.phoneNumber = allReceivers.phoneNumber;
                           filteredUsers.push(user);
                       }
                    })
                    setPortalUserData(filteredUsers);
                    setCreateAlert(true)
                }}
              >
                <Add sx={{color: '#fff'}} />
              </Button>
          :
            null
      }

      {/* EDIT PERMISSION DIALOG */}

      {editUnlockAlert ? (
        <>
          <ConfigureAlert
            title="Update Alert"
            generalUser={generalUser}
            portal_users_data={PortalUsersData}
            data={alertsData}
            allData={rows}
            show_alert_dialog={editUnlockAlert}
            close_edit_alert_dialog={(value) =>
              handleCloseEditUnlockAlert(value)
            }
            portalUserData={portalUserData}
            selectedUsers={selectedUsers}
            themeColor={primaryThemeColor}
            button_name="Update"
            dialog="CreateNewAlert"
          />
        </>
      ) : createAlert ? (
        <>
          <ConfigureAlert
            title="Create New Alert"
            generalUser={generalUser}
            selectedUsers={generalUser ? selectedUsers : []}
            portal_users_data={PortalUsersData}
            portalUserData={portalUserData}
            data={[]}
            allData={rows}
            show_alert_dialog={createAlert}
            close_edit_alert_dialog={(value) => handleCreateAlert(value)}
            button_name="Create"
            themeColor={primaryThemeColor}
            dialog="CreateNewAlert"
          />
        </>
      ) : deleteAlert ? (
        <>
          <ConfigureAlert
            delete_alert={true}
            title="Delete Alert"
            generalUser={generalUser}
            portal_users_data={[]}
            data={deleteAlertData}
            themeColor={primaryThemeColor}
            show_alert_dialog={deleteAlert}
            close_edit_alert_dialog={(value) => handleDeleteAlert(value)}
            button_name="Yes"
            dialog="CreateNewAlert"
          />
        </>
      ) : subscribeToEmailAlert ? (
        <>
          <SubscribeOrUnsubscribeToEmailAlert
            title="Alert Subscription"
            showSubscribeDialog={subscribeToEmailAlert}
            data={emailAlertData}
            sendEmailAlert={sendEmailAlert}
            setToggleToPreviousState={() => {
                emailAlertData.emailAlert = false
            }}
            closeDialog={() => setSubscribeToEmailAlert(false)}
            dialog="SubscribeToEmailAlert"
          />
        </>
      ) : unsubscribeToEmailAlert ? (
        <>
          <SubscribeOrUnsubscribeToEmailAlert
            title="Unsubscribe Email alert"
            showUnsubscribeDialog={unsubscribeToEmailAlert}
            data={emailAlertData}
            sendEmailAlert={sendEmailAlert}
            setToggleToPreviousState={() => {
              emailAlertData.emailAlert = true
            }}
            closeDialog={() => setUnsubscribeToEmailAlert(false)}
            dialog="UnsubscribeToEmailAlert"
          />
        </>
      ) : subscribeToSmsAlert ? (
        <>
          <SubscribeOrUnsubscribeToSmsAlert
            title="Alert Subscription"
            showSubscribeDialog={subscribeToSmsAlert}
            data={smsAlertData}
            sendSmsAlert={sendSmsAlert}
            mobileNumberExists={isMobileNumberExists}
            userData={generalUserData}
            setToggleToPreviousState={() => {
              smsAlertData.smsAlert = false
            }}
            closeDialog={() => setSubscribeToSmsAlert(false)}
            dialog="SubscribeToSmsAlert"
          />
        </>
      ) : unsubscribeToSmsAlert ? (
        <>
          <SubscribeOrUnsubscribeToSmsAlert
            title="Unsubscribe SMS alert"
            showUnsubscribeDialog={unsubscribeToSmsAlert}
            data={smsAlertData}
            sendSmsAlert={sendSmsAlert}
            setToggleToPreviousState={() => {
              smsAlertData.smsAlert = true
            }}
            closeDialog={() => setUnsubscribeToSmsAlert(false)}
            dialog="UnsubscribeToSmsAlert"
          />
        </>
      ) : null}
    </>
  );
}

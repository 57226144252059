import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery, useTheme } from '@mui/material';
import { Close } from '@mui/icons-material';
import JobSchedulerClient from "clients/JobSchedulerClient";
import checkDataExistsOrNot from 'helper-components/checkDataExistsOrNot.js';
import { useSnackbar } from 'notistack';
import MqttEventHandler from 'components/Mqtt/MqttEventHandler';
import PubSubChannels from 'pubsub/PubSubChannels';
import _ from 'lodash';

const SubscribeOrUnsubscribeToEmailAlert = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { enqueueSnackbar } = useSnackbar();
    const [submitted, setSubmitted] = React.useState(false);

    const subscribeOrUnsubscribeToEmailAlerts = () => {
        if(!submitted){
            setSubmitted(true);
            let receivers = [];
            let alreadySaved = false;
            if (props.showSubscribeDialog){
              _.forEach(props.data.receivers, (receiver) => {  
                if (receiver.phoneNumber === JSON.parse(sessionStorage.userDetails).user.phoneNumber){
                  receiver["email"] = JSON.parse(sessionStorage.userDetails).user.email;
                  alreadySaved = true;
                }
                receivers.push(receiver);
              })
              if (!alreadySaved){
                receivers.push({
                  "email": JSON.parse(sessionStorage.userDetails).user.email,
                  "phoneNumber": null,
                });
              }
            }else {
              _.forEach(props.data.receivers, (receiver) => {  
                if (receiver.phoneNumber !== null && receiver.phoneNumber === JSON.parse(sessionStorage.userDetails).user.phoneNumber){
                  receiver["email"] = null;
                }
                if (JSON.parse(sessionStorage.userDetails).user.email !== receiver["email"])
                  receivers.push(receiver);
              })
            }
            window.receivers = receivers;
            let payload = {
              id : props.data.id,
              alertType : props.data.alertType,
              alertName : props.data.alertName,
              waitingTime : props.data.waitingTime,
              thresholdType : props.data.thresholdType,
              threshold : props.data.threshold,
              receivers : receivers,
              emailAlert : getEmailAlertVal(),
              smsAlert : getSmsAlertVal(),
              scopeId: sessionStorage.tenantId,
              userId: props.data.userId
            }
            let json = setPublishData();
            JobSchedulerClient.updateAlert(payload).then((res) => {
              if(res.status === 200 || res.status === 204 || res.status === 415){
                enqueueSnackbar("Successfully Subscribed to Email Alert");
                if(checkForReceivers() && props.data.alertType !== "CAMERA_MODULE_ALERT") {
                  MqttEventHandler.publish(PubSubChannels.pubsub_channels.GENERIC_PUBLISH_CHANNEL.replace("MAC",sessionStorage.tenantId), JSON.stringify(json), {qos: 2, retain: false}, function() {});
                }
              }else {
                props.setToggleToPreviousState();
                enqueueSnackbar("Failed to Subscribe to Email Alert");
              }
              props.closeDialog();
            });
        }
    }

    const setPublishData = () => {
        if (props.data.alertType !== "CAMERA_MODULE_ALERT") {
            let json = {};
            let type = props.dialog === "SubscribeToEmailAlert" ? "_C" : "_D";
            let alertType = props.data.alertType;
            let alert = alertType === "HIGH_CPU" ? "CP" : alertType === "HIGH_MEMORY_USAGE" ? "MM" : alertType === "HIGH_TEMP" ? "TEMP" : alertType === "HIGH_CO2" ? "CO2" : alertType === "HIGH_NH3" ? "NH3" : alertType === "HIGH_NO2" ? "NO2" : alertType === "HIGH_CO" ? "CO" : alertType === "HIGH_CO2_CTN" ? "CO2" : alertType === "HIGH_RSTC" ? "RSTC" : alertType === "HIGH_TVOC" ? "TVOC" : "camera-alert";
            let thresholdType = props.data.thresholdType === "greater_than" ? "_H" : "_L";
            let threshold = "_" + props.data.threshold;
            if (alertType === "HIGH_CPU" || alertType === "HIGH_MEMORY_USAGE") {
              json = {
                "sys" : alert + thresholdType + threshold + type
              }
            }else if (alertType === "HIGH_TEMP" || alertType === "HIGH_CO2" ) {
              json = {
                "co2_sensor" : alert + thresholdType + threshold + type
              }
            } else if (alertType === "HIGH_NH3" || alertType === "HIGH_NO2" || alertType === "HIGH_CO") {
              json = {
                "air_quality_5_sensor" : alert + thresholdType + threshold + type
              }
            } else if (alertType === "HIGH_CO2_CTN" || alertType === "HIGH_RSTC" || alertType === "HIGH_TVOC") {
              json = {
                "air_quality_2_sensor" : alert + thresholdType + threshold + type
              }
            }
            return json;
        }
    }

    const getEmailAlertVal = () => {
        let receivers = window.receivers;
        if(receivers.length === 0){
            return false;
        }
        return _.some(receivers, (receiver) => {
            if(receiver["email"] !== null){
                return true;
            }
        return false;
        })
    }

    const getSmsAlertVal = () => {
        let receivers = window.receivers;
        if(receivers.length === 0){
            return false;
        }
        return _.some(receivers, (receiver) => {
            if(receiver["phoneNumber"] !== null){
                return true;
            }
        return false;
        })
    }

    const checkForReceivers = () => {
        let receivers = window.receivers;
        if(receivers.length === 0){
            return true;
        }else{
            return receivers.length === 1 ? props.showSubscribeDialog ? !checkDataExistsOrNot(receivers[0]["phoneNumber"]) : false : false;
        }
    }

    return (
        <div>
            <Dialog
                fullWidth={true}
                fullScreen={fullScreen}
                open={props.dialog === "SubscribeToEmailAlert" ? props.showSubscribeDialog : props.showUnsubscribeDialog}
                onClose={() => {props.closeDialog();props.setToggleToPreviousState()}}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                    <Box sx={{flex: '95'}}>{props.title}</Box>
                    <Box sx={{flex: '5', marginTop: '6px'}}>
                        <Close onClick={()=>{props.closeDialog();props.setToggleToPreviousState()}} sx={{cursor:"pointer"}}/>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        { props.showSubscribeDialog ?
                            <>Are you sure you want to Subscribe to {props.data.alertName} Email Alert?</>
                        :
                            <>Are you sure you want to Unsubscribe to {props.data.alertName} Email Alert?</>
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{display:'flex', justifyContent:"end", position:"relative", height:"40px"}}>
                    <>
                        <Button onClick={() => {props.closeDialog();props.setToggleToPreviousState()}}>cancel</Button>
                        <Button onClick={() => subscribeOrUnsubscribeToEmailAlerts()}>
                            Yes
                        </Button>
                    </>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default SubscribeOrUnsubscribeToEmailAlert;

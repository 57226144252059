import { Done, Email, KeyboardAlt, Lock, Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, Checkbox, CircularProgress, FormHelperText, IconButton, InputAdornment, List, ListItem, ListItemAvatar, ListItemText, Paper, TextField, Typography, FormControl, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import AuthClient from "clients/AuthClient";
import checkDataExistsOrNot from 'helper-components/checkDataExistsOrNot';
import fingerprintGenerator from 'helper-components/fingerPrintGenerator';
import md5 from 'md5';
import * as React from 'react';
import { useLocation } from "react-router-dom";
import 'stylesheet/Login.css';
import UserSessionStorage from 'userSession/UserSessionStorage';
import "stylesheet/HomePage.css";
import PhoneInput, { getCountryCallingCode } from 'react-phone-number-input';
import bcrypt from 'bcryptjs';
import caribouLogo from "./icons/caribouLogo.png";
import iotIcon from "./icons/iotIcon.png";

const Item = styled(Paper)(({theme}) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function Login() {
  const [password, setPassword] = React.useState('');
  const [name, setName] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [showForgotPwdVw, setShowForgotPwdVw] = React.useState(false);
  const [showLogin, setShowLogin] = React.useState(true);
  const [resetLinkSent, setResetLinkSent] = React.useState(false);
  const [orangeColor, setOrangeColor] = React.useState(false);
  const [orangeColorPassword ,setOrangeColorPassword] = React.useState(false);
  const [showErrorMessage, setShowErrorMessage] = React.useState(false);
  const [showPasswordResetErrorMessage, setShowPasswordResetErrorMessage] = React.useState(false);
  const [passwordType, setPasswordType] = React.useState("password");
  const [showSecondStep, setShowSecondStep] = React.useState(false);
  const [securityCode, setSecurityCode] = React.useState("");
  const [trustDevice, setTrustDevice] = React.useState(false);
  const [fingerPrintKey, setFingerPrintKey] = React.useState("");
  const [showAuthoriseError, setShowAuthoriseError] = React.useState(false);
  const [expireTime, setExpireTime] = React.useState("1D");
  const [showSignUp, setShowSingUp] = React.useState(false)
  const [firstName, setFirstName] = React.useState('');
  const [firstNameTextLength, setFirstNameTextLength] = React.useState(false);
  const [firstNameErrorText, setFirstNameErrorText] = React.useState(false);
  const [lastName, setLastName] = React.useState('');
  const [lastNameTextLength, setLastNameTextLength] = React.useState(false);
  const [lastNameErrorText, setLastNameErrorText] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [emailTextLength, setEmailTextLength] = React.useState(false);
  const [emailErrorText, setEmailErrorText] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [countryCode, setCountryCode] = React.useState('+1');
  const [countryFlag, setCountryFlag] = React.useState('US');
  const [isValidDataOrNot, setIsValidDataOrNot] = React.useState(false);
  const [phoneNumberErrorText, setPhoneNumberErrorText] = React.useState(false);
  const [userCreationFlag, setUserCreationFlag] = React.useState(false);
  const [signUpStatus, setSignUpStatus] = React.useState(false);
  const [showConflictMessage, setShowConflictMessage] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const salt = bcrypt.genSaltSync(10);
  const location = useLocation();



  React.useEffect(()=>{
    sessionStorage.clear();
    fingerprintGenerator().then((res) => {
      setFingerPrintKey(res);
    })
    window.userData = {
        firstName : null,
        firstNameTextLength: null,
        lastName : null,
        lastNameTextLength: null,
        email : null,
        phoneNumber : null,
        countryCode : '+1',
        permissions : null,
    }
  },[])

  let user_details = {};
  const roles = [
    {
      value: 'Admin',
      label: "Admin",
    },
    {
      value: "Super Admin",
      label: "Super Admin"
    }
  ]

  const login = () => {
      user_details.email = name.toLowerCase();
      user_details.password = password;
      setIsLoading(true);
      fingerprintGenerator().then((res) => {
          AuthClient.login(user_details,res).then((response) => {
             if (!response.hasOwnProperty("message")){
                 let data = res + user_details.email;
                 let hash = bcrypt.hashSync(data, salt);
                 let key = hash.substring(0,16);
                 let keyWithTime = key + new Date().getTime();
                 sessionStorage.activityKey = bcrypt.hashSync(keyWithTime, salt).substring(0,32);
                 if (response.trustedDevice === true) {
                     setShowSecondStep(false);
                     AuthClient.fetchUserDetails().then(response =>{
                          window.userDetails = response;
                          sessionStorage.showMqttClass = true;
                          sessionStorage.userLoggedIn = true;
                          sessionStorage.userPasscode = md5(user_details.password);
                          sessionStorage.setItem("userDetails",JSON.stringify(response));
                          if(response.isRootUser){
                              sessionStorage.isRootUser = true;
                              setIsLoading(false);
                              sessionStorage.tenantPrimaryThemeColor = "#4190c7";
                              sessionStorage.tenantSecondaryThemeColor = "#ffffff";
                              saveLoginActivity();
                              window.location.hash = "#/admin/tenants";
                              location.hash = "#/admin/tenants";
                          } else {
                              setIsLoading(false);
                              sessionStorage.isRootUser = false;
                              sessionStorage.tenantId = response.user.scopeId;
                              AuthClient.getHirarchy().then((data) => {
                                if (data.scope.id === response.user.scopeId){
                                  UserSessionStorage.setTenantName(data.scope.name);
                                  sessionStorage.tenantName = data.scope.name;
                                  sessionStorage.tenantPrimaryThemeColor = checkDataExistsOrNot(data.scope.primaryThemeColor) ? data.scope.primaryThemeColor.indexOf("#") !== -1 ? data.scope.primaryThemeColor : "#" + data.scope.primaryThemeColor : "#4190c7";
                                  sessionStorage.tenantSecondaryThemeColor = checkDataExistsOrNot(data.scope.secondaryThemeColor) ? data.scope.secondaryThemeColor.indexOf("#") !== -1 ? data.scope.secondaryThemeColor : "#"+ data.scope.secondaryThemeColor : "#ffffff";
                                  sessionStorage.logoUrl = checkDataExistsOrNot(data.scope.logoUrl) ? data.scope.logoUrl : "";
                                  saveLoginActivity();
                                  window.location.hash = "#/cli/dashboard"
                                  location.hash = "#/cli/dashboard";
                                }
                              });
                          }
                     });
                 } else {
                     if (response.secureAuthentication === true) {
                        setShowSecondStep(true);
                        setIsLoading(false);
                     } else {
                        setShowSecondStep(false);
                        AuthClient.fetchUserDetails().then(response =>{
                          window.userDetails = response;
                          sessionStorage.showMqttClass = true;
                          sessionStorage.userLoggedIn = true;
                          sessionStorage.userPasscode = md5(user_details.password);
                          sessionStorage.setItem("userDetails",JSON.stringify(response));
                          if(response.isRootUser){
                              sessionStorage.isRootUser = true;
                              setIsLoading(false);
                              sessionStorage.tenantPrimaryThemeColor = "#4190c7";
                              sessionStorage.tenantSecondaryThemeColor = "#ffffff";
                              saveLoginActivity();
                              window.location.hash = "#/admin/tenants";
                              location.hash = "#/admin/tenants";
                          }else{
                              setIsLoading(false);
                              sessionStorage.isRootUser = false;
                              sessionStorage.tenantId = response.user.scopeId;
                              AuthClient.getHirarchy().then((data) => {
                                if (data.scope.id === response.user.scopeId){
                                  UserSessionStorage.setTenantName(data.scope.name);
                                  sessionStorage.tenantName = data.scope.name;
                                  sessionStorage.tenantPrimaryThemeColor = checkDataExistsOrNot(data.scope.primaryThemeColor) ? data.scope.primaryThemeColor.indexOf("#") !== -1 ? data.scope.primaryThemeColor : "#" + data.scope.primaryThemeColor : "#4190c7";
                                  sessionStorage.tenantSecondaryThemeColor = checkDataExistsOrNot(data.scope.secondaryThemeColor) ? data.scope.secondaryThemeColor.indexOf("#") !== -1 ? data.scope.secondaryThemeColor : "#"+ data.scope.secondaryThemeColor : "#ffffff";
                                  sessionStorage.logoUrl = checkDataExistsOrNot(data.scope.logoUrl) ? data.scope.logoUrl : "";
                                  saveLoginActivity();
                                  window.location.hash = "#/cli/dashboard"
                                  location.hash = "#/cli/dashboard";
                                }
                              });
                          }
                        });
                     }
                 }
             } else {
                  setShowErrorMessage(true);
                  setTimeout(() => {
                    setShowErrorMessage(false);
                  }, 3000);
                  setIsLoading(false);
                  sessionStorage.userLoggedIn = false;
             }
          });
      });
  };

   const authoriseUser = () => {
      setIsLoading(true);
      AuthClient.TwoStepVerification(name,securityCode,fingerPrintKey,expireTime).then((response) => {
         if(response.status >= 200 && response.status< 300){
            AuthClient.fetchUserDetails().then(response =>{
               window.userDetails = response;
               sessionStorage.showMqttClass = true;
               sessionStorage.userLoggedIn = true;
               sessionStorage.userPasscode = md5(password);
               sessionStorage.setItem("userDetails",JSON.stringify(response));
               if(response.isRootUser){
                   sessionStorage.isRootUser = true;
                   setIsLoading(false);
                   sessionStorage.tenantPrimaryThemeColor = "#4190c7";
                   sessionStorage.tenantSecondaryThemeColor = "#ffffff";
                   saveLoginActivity();
                   window.location.hash = "#/admin/tenants";
                   location.hash = "#/admin/tenants";
               } else {
                   setIsLoading(false);
                   sessionStorage.isRootUser = false;
                   sessionStorage.tenantId = response.user.scopeId;
                   AuthClient.getHirarchy().then((data) => {
                      if (data.scope.id === response.user.scopeId){
                        UserSessionStorage.setTenantName(data.scope.name);
                        sessionStorage.tenantName = data.scope.name;
                        sessionStorage.tenantPrimaryThemeColor = checkDataExistsOrNot(data.scope.primaryThemeColor) ? data.scope.primaryThemeColor.indexOf("#") !== -1 ? data.scope.primaryThemeColor : "#" + data.scope.primaryThemeColor : "#4190c7";
                        sessionStorage.tenantSecondaryThemeColor = checkDataExistsOrNot(data.scope.secondaryThemeColor) ? data.scope.secondaryThemeColor.indexOf("#") !== -1 ? data.scope.secondaryThemeColor : "#"+ data.scope.secondaryThemeColor : "#ffffff";
                        sessionStorage.logoUrl = checkDataExistsOrNot(data.scope.logoUrl) ? data.scope.logoUrl : "";
                        saveLoginActivity();
                        window.location.hash = "#/cli/dashboard"
                        location.hash = "#/cli/dashboard";
                      }
                   });
               }
            });
         } else {
             setShowAuthoriseError(true);
             setTimeout(() => {
               setShowAuthoriseError(false);
             }, 3000);
             setIsLoading(false);
             sessionStorage.userLoggedIn = false;
         }
      });
   }

   const saveLoginActivity = () => {
       let payload = {
         "scopeId": JSON.parse(sessionStorage.userDetails).user.scopeId,
         "email": JSON.parse(sessionStorage.userDetails).user.email,
         "login" : {"ti" : [new Date().getTime()]}
       };
       AuthClient.saveLoginOrLogoutActivity(payload,sessionStorage.activityKey,"login").then((response) => {});
   }

    const forgotPassword = () => {
        user_details.email = name;
        setIsLoading(true);
        sessionStorage.clear();
        AuthClient.forgotPassword(response => {
            if (response.status === 200) {
              setIsLoading(false);
              sessionStorage.userLoggedIn = true;
              setResetLinkSent(true);
              setTimeout(() => {
                window.location.reload();
              }, 3000);
            } else {
              setIsLoading(false);
              setShowPasswordResetErrorMessage(true);
              setTimeout(() => {
                setShowPasswordResetErrorMessage(false);
              }, 1500);

            }

        }, user_details);
    };

    const showForgotPwd = () => {
        setShowForgotPwdVw(true);
    };
    const hideForgotPwd = () => {
        setShowForgotPwdVw(false);
    };

    const togglePassword =()=>{
        if(passwordType==="password")
        {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    const showSignUpView = () => {
        if(showSignUp){
            setShowSingUp(false);
            setFirstName('');
            setLastName('');
            setEmail('');
            setCountryFlag('US');
            setCountryCode('+1');
            setPhoneNumber('');
            setFirstNameErrorText(false);
            setLastNameErrorText(false);
            setEmailErrorText(false);
            setPhoneNumberErrorText(false);
            window.userData = {};
            checkIsValidDataOrNot();
        }else{
            setShowSingUp(true);
        }

    }

    const checkSpace = (name) => {
      if(name === " " || name.indexOf(" ") === 0){
          if(name.length <= 1){
              return "";
          }else{
              return name ? name.trim() : "";
          }
      }
    }

    const isValidName = (name) => {
        return !name.match("^[a-zA-Z0-9+_.\-]+@[a-zA-Z0-9_.\-]+[.]{1}[a-z]{2,3}$")
    }

    const isValidEmail = (email) => {
        const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
        return pattern.test(email) ? true : false;
    }

    const validMobileNumber = (number) => {
       if (checkDataExistsOrNot(number)) {
          if (number.match("^[0-9]+$") && (number.length >= 7 && number.length <= 15)) {
             return true;
          } else {
             return false;
          }
       } else {
         return false;
       }
    }

    const checkIsValidDataOrNot = () => {
      if (checkDataExistsOrNot(window.userData.firstName) && isValidName(window.userData.firstName) && checkDataExistsOrNot(window.userData.lastName) && isValidName(window.userData.lastName) && checkDataExistsOrNot(window.userData.email) && isValidEmail(window.userData.email)) {
             if (checkDataExistsOrNot(window.userData.phoneNumber) || checkDataExistsOrNot(window.userData.countryCode)) {
                   checkDataExistsOrNot(window.userData.countryCode) ? checkDataExistsOrNot(window.userData.phoneNumber) ? setIsValidDataOrNot(validMobileNumber(window.userData.phoneNumber)) : setIsValidDataOrNot(true) : setIsValidDataOrNot(false);
             } else {
                  setIsValidDataOrNot(true);
                  setCountryCode('');
             }
      } else {
        setIsValidDataOrNot(false);
      }
    }

    const handleOnChange = (val) => {
       setCountryCode(val);
       window.userData.countryCode = val;
       checkIsValidDataOrNot();
       document.getElementById("mobileField").focus();
    }

    const createPortalUser = () => {

        setIsLoading(true);
        AuthClient.getGeneralUsersScopeId().then((response) => {
            let payload={
                "firstName": firstName,
                "firstNameTextLength": firstNameTextLength,
                "lastName": lastName,
                "lastNameTextLength": lastNameTextLength,
                "email": email.toLowerCase(),
                "scopeId": response,
                "phoneNumber": checkDataExistsOrNot(window.userData.countryCode) && checkDataExistsOrNot(phoneNumber) ? window.userData.countryCode+phoneNumber : "",
                "countryShortCode": checkDataExistsOrNot(countryFlag) && checkDataExistsOrNot(phoneNumber) ? countryFlag : "",
                "permissions": [
                    "IOT_DEVICE_READ",
                    "PORTAL_USER_READ",
                    "IOT_USER_READ",
                    "ALERTS_READ",
                    "ALERTS_WRITE",
                    "SCOPE_READ",
                    "FIRMWARE_READ",
                    "GATEWAY_INSTRUCTIONS_READ",
                    "GATEWAY_READ",
                    "APP_DETAILS",
                    "MQTT_AUTH",
                    "PORTAL_USER_DETAILS",
                    "PORTAL_USER_PASSWORD_CHANGE",
                    "HIERARCHY_READ",
                    "PORTAL_USER_WRITE"
                ],
            }
            AuthClient.portalUserSignup(payload).then((response) => {
                if (response.status === 200 || response.status === 201){
                    let data = fingerPrintKey + payload.email;
                    let hash = bcrypt.hashSync(data, salt).substring(0,16);
                    let keyWithTime = hash + new Date().getTime();
                    let activityKey = bcrypt.hashSync(keyWithTime, salt).substring(0,32);
                    let signUpPayload = {
                        "scopeId": payload.scopeId,
                        "email": payload.email,
                        "signUp": {"time": [new Date().getTime()]}
                    };
                    AuthClient.saveLoginOrLogoutActivity(signUpPayload,activityKey,"signUp").then((response) => {});
                    setTimeout(() => {
                        setIsSuccess(true);
                        setIsLoading(false);
                    },1000)
                    setTimeout(() => {
                        window.location.reload();
                    }, 4000);
                } else {
                    response.json().then((res) => {
                        if (res.message.indexOf("409") !== -1) {
                           setTimeout(() => {
                              setIsLoading(false);
                              setIsSuccess(false);
                              setSignUpStatus(true);
                              setShowConflictMessage(true);
                           },1000)
                           setTimeout(() => {
                              setSignUpStatus(false);
                           },4000)
                        } else {
                            setTimeout(() => {
                                setIsSuccess(false);
                                setSignUpStatus(true);
                                setIsLoading(false);
                                setShowConflictMessage(false);
                            },1000)
                            setTimeout(() => {
                               setSignUpStatus(false);
                            },4000)
                        }
                    })
                }
            });
        });
    }

  return (
  <>

    <Box
      sx={{   
        background:"linear-gradient(180deg, #18BCEC 0%, #3A90FC 100%) !important",     
        overflowY: 'auto',
        height: '100%',
        display: 'flex',
        color:'#000000DE !important',
        flexDirection: 'column',
      }}
      className="loginComponent"
    >
          <Box
            sx={{
              background:"linear-gradient(180deg, #18BCEC 0%, #3A90FC 100%) !important",
              paddingBottom: '1px',
              marginTop: '-6px',
              height: 'calc(98% - 2px)'
            }}
          >
            <Box sx={{ display:"flex",flexDirection:"column", height: 'calc(100% + 21px)'}}>
              <Box className="middleComponent" sx={{ display:"flex",height:"100%", width:"100%"}}>
                <Box sx={{display:"flex", flexDirection:"column",height:"100%", width:"100%"}}>
                  <Box className="gatewayImage midThreeComponents" sx={{height:"50%",width:"100%", display:"flex", justifyContent:"center",alignItems:"center"}}>
                    <Box>
                      <img src={iotIcon} width={630} height={400}/>
                    </Box>
                  </Box>
                  <Box sx={{height:"50%",width:"91%",margin:"30px 50px 0px 50px", display:"flex", flexDirection:"row", justifyContent:"center"}}>                    
                    <ul style={{color:"#fff",fontSize:"28px"}}>
                      <li>
                        <Typography sx={{fontSize:"24px",color:"#fff",fontWeight:"500"}}>
                          Edge Sensor Integration Using Caribou IoT Gateway
                        </Typography>
                      </li>
                      <li style={{marginTop: "14px"}}>
                        <Typography sx={{fontSize:"24px",color:"#fff",fontWeight:"500"}}>
                          Secure, Robust HW/SW Solution
                        </Typography>
                      </li>
                      <li style={{marginTop: "14px"}}>
                        <Typography sx={{fontSize:"24px",color:"#fff",fontWeight:"500"}}>
                          Production Proven Platform Deployed In High Volume
                        </Typography>
                      </li>
                      <li style={{marginTop: "14px"}}>
                        <Typography sx={{fontSize:"24px",color:"#fff",fontWeight:"500"}}>
                          Customizable Solution For Any IoT Platform.
                        </Typography>  
                      </li>
                    </ul>
                  </Box>
                </Box>
                <Box className="logInBox midThreeComponents" sx={{display:"flex",marginLeft:"auto",background:"#fff"}}>
                    { !showSignUp ?
                        <Box
                          className="logInWidth"
                          sx={{
                            backgroundColor: !isLoading ? '#fff' : '#fff',
                            position: 'relative',
                            width: '100% !important',
                            margin: '30px',
                            opacity: isLoading && !resetLinkSent ? 0.5 : null,
                          }}
                        >
                          <Box sx={{height:"150px", width:"100%"}}>
                            <img src={caribouLogo} height={135} width={263} />
                          </Box>

                          <Box sx={{height:"41px", width:"100%",color: 'rgb(0,122,255)'}}>
                            <Typography sx={{fontSize: '27px', fontFamily: 'Poppins', fontWeight:'600'}}>
                              {showForgotPwdVw ? "Enter your email address" : "Sign in"}
                            </Typography>
                          </Box>
                          <Box>
                              <Box
                                sx={{
                                  display: !resetLinkSent ? 'flex' : 'none',
                                  alignItems: 'flex-end',
                                  margin: '18px 0',
                                  padding:'2px 0px 2px 0px',
                                  fontFamily:"Poppins !important"
                                }}
                              >
                                <Email sx={{color: showSecondStep ? 'rgba(0,122,255,1)' : orangeColor ? 'rgba(0,122,255,1)' : 'rgba(0,122,255,1)', mr: 1, my: 0.5}} />
                                <TextField
                                  disabled={showSecondStep}
                                  fullWidth
                                  id="input-with-sx"
                                  type="email"
                                  label={showForgotPwdVw ? "Email Address" : "Username"}
                                  variant="standard"
                                  value={name}
                                  error={name ? (name.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) ? false : true) : false}
                                  onFocus={()=>setOrangeColor(true)}
                                  onBlur={()=>setOrangeColor(false)}
                                  onKeyPress={(event)=>{
                                    if (event.key === 'Enter'){
                                      login();
                                    }
                                  }}
                                  InputLabelProps={{
                                    style: { color: showSecondStep ? null : orangeColor? '#4190c7' : null , fontSize:"20px"},
                                  }}
                                  onChange={(event) => {
                                    setName(event.target.value);
                                  }}
                                  sx={{padding:"2px",textIndent:'4px',borderRadius:"10px",fontFamily:"Poppins !important"}}
                                />
                              </Box>
                              <Box sx={{height:'1px'}}>
                              </Box>
                              {
                                showPasswordResetErrorMessage ?
                                  <Box sx={{textAlign:"center",fontSize:"14px",color:"red"}}>
                                    Error: Please try again
                                  </Box>
                                :
                                  null
                              }
                              { showSecondStep ?
                                 <Box sx={{margin:"-29px 0px 20px 1px"}}>
                                     <Box
                                         sx={{
                                           display: !resetLinkSent ? 'flex' : 'none',
                                           alignItems: 'flex-end',
                                           margin: '18px 0',
                                           padding:'2px 0px 2px 0px',
                                           position: 'relative',
                                           top: '29px',
                                         }}
                                     >
                                        <KeyboardAlt sx={{color: orangeColor ? 'rgba(0,122,255,1)' : 'rgba(0,122,255,1)', mr: 1, my: 0.5, position: 'relative', left: '-1px'}} />
                                        <TextField
                                           fullWidth
                                           id="input-with-sx"
                                           label="Enter authenticator code"
                                           variant="standard"
                                           value={securityCode}
                                           //error={securityCode ? (securityCode.match("^[0-9]{6}$") ? false : true) : false}
                                           onFocus={()=>setOrangeColor(true)}
                                           onBlur={()=>setOrangeColor(false)}
                                           InputLabelProps={{
                                             style: { color: orangeColor? '#4190c7' : null },
                                             shrink:true,
                                             fontSize:"20px"
                                           }}
                                           onChange={(event) => {
                                             setSecurityCode(event.target.value);
                                           }}
                                           sx={{padding:"2px",textIndent:'4px',borderRadius:"10px"}}
                                        />
                                     </Box>
                                     <Box sx={{marginTop:'5px', visibility: showAuthoriseError ? 'visible' : 'hidden',position: 'relative',top: '25px',left: '33px'}}>
                                        <FormHelperText variant="standard" error={true} >You have entered an Incorrect code.</FormHelperText>
                                     </Box>
                                     <Box sx={{marginTop: "36px",marginLeft: '0px'}}>
                                        <Typography sx={{display: "flex",flexDirection: "row"}}>
                                           <Checkbox
                                             size="small"
                                             checked={trustDevice}
                                             onChange={(event) => {
                                               if (event.target.checked) {
                                                  setExpireTime("30D");
                                               } else {
                                                  setExpireTime("1D");
                                               }
                                               setTrustDevice(event.target.checked);
                                             }}
                                             color="primary"
                                             inputProps={{
                                                 color:"#4190c7"
                                             }}
                                             sx={{margin: '0px', padding: '0px', position:"relative", top:"3px"}}
                                           />
                                           &nbsp;&nbsp;&nbsp;
                                           <Box sx={{display: 'flex',flexDirection: 'column',position: 'relative',top: '3px',fontSize: '11px'}}>
                                             Trust this device
                                             <span style={{fontSize: '9px',color: 'rgba(0,0,0,0.54)',marginLeft: '3px'}}>We won't ask for a code next time</span>
                                           </Box>
                                        </Typography>
                                     </Box>
                                 </Box>
                              :
                                 <Box
                                    sx={{
                                      display: showSecondStep ? 'none' : showForgotPwdVw ? 'none' : 'flex',
                                      alignItems: 'flex-end',
                                      margin: '18px 0',
                                      padding:'2px 0px 2px 0px',
                                    }}
                                 >
                                    <Lock sx={{color: orangeColorPassword ? 'rgba(0,122,255,1)' : 'rgba(0,122,255,1)', mr: 1, my: 0.5}} />
                                    <TextField
                                      fullWidth
                                      id="input-with-sx"
                                      label="Password"
                                      type={passwordType}
                                      variant="standard"
                                      value={password}
                                      onChange={(event) => {
                                        setPassword(event.target.value);
                                      }}

                                      onFocus={()=>setOrangeColorPassword(true)}
                                      onBlur={()=>setOrangeColorPassword(false)}
                                      InputLabelProps={{
                                        style: { color: orangeColorPassword ? '#4190c7' : null , fontSize:"20px"},
                                      }}
                                      InputProps={{
                                         endAdornment: <InputAdornment position="end">
                                              <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={togglePassword}
                                                onMouseDown={togglePassword}
                                                edge="end"
                                              >
                                                {passwordType==="password" ? <VisibilityOff /> : <Visibility />}
                                              </IconButton>
                                          </InputAdornment>
                                      }}
                                      onKeyPress={(event)=>{
                                        if (event.key === 'Enter'){
                                          login();
                                        }
                                      }}
                                      sx={{padding:"2px",textIndent:'4px',borderRadius:"10px"}}
                                    />
                                 </Box>
                              }
                          </Box>
                          <Box sx={{height:'1px',marginBottom:"20px"}}>
                          </Box>
                          { showSecondStep ?
                              <Box
                                sx={{
                                  alignItems: 'center',
                                  display: 'flex',
                                  margin: '8px',
                                  textAlign: 'right',
                                  flexDirection: 'row',
                                  position: 'relative',
                                  top: '-23px'
                                }}
                              >
                                <Button
                                  variant="text"
                                  style={{
                                    minWidth: '88px',
                                    margin: '8px 6px',
                                    color: 'rgba(0,0,0,0.87)',
                                  }}
                                  onClick={() => {
                                    setShowSecondStep(false);
                                    setSecurityCode("");
                                    setTrustDevice(false);
                                  }}
                                >
                                  BACK
                                </Button>
                                <Button
                                   variant="contained"
                                   disabled={ securityCode.length === 6 ? false : true }
                                   style={{
                                     minWidth: '88px', margin: '8px 6px',
                                     color: securityCode.length === 6 ? 'rgba(255,255,255,0.87)' : 'rgba(0,0,0,0.12)',
                                     backgroundColor: securityCode.length === 6 ? '#4190c7' : 'rgba(0,0,0,0.12)',
                                     width: '65%'
                                   }}
                                   onClick={() => authoriseUser()}
                                >
                                    AUTHORISE
                                </Button>
                                {
                                    isLoading ?
                                        <Box sx={{width: 'calc(100% - 17px)',display: 'flex',justifyContent: 'center',alignItems: 'center',height: '100%',position: 'absolute',top: '-139px',right:'8px'}}>
                                            <CircularProgress thickness="4.4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                                        </Box>
                                    :
                                     null
                                }
                              </Box>
                          :
                              <Box component="span"
                                  sx={{
                                      display: showSecondStep ? 'none' : showForgotPwdVw ? 'none' : 'block',
                                      fontFamily:"Poppins",
                                  }}>
                                {
                                  showErrorMessage ?
                                    <Box sx={{color: 'red',fontSize: '12px',display: 'flex',alignItems: 'center',justifyContent: 'center'}}>
                                      Invalid Username or Password
                                    </Box>
                                  :
                                    null
                                }
                                <Button
                                  variant="contained"
                                  style={{
                                    width: '100%',
                                    marginTop: '20px',
                                    fontSize:"17px",
                                    fontFamily:'Poppins',
                                    fontWeight:600,
                                    borderRadius:"12px",
                                    color: 'rgba(255,255,255,0.87)',
                                    backgroundColor: '#4190c7',                                    
                                  }}
                                  onClick={() => login()}
                                >
                                  LOGIN
                                </Button>
                                {
                                    isLoading ?
                                        <Box sx={{width: 'calc(100% - 17px)',display: 'flex',justifyContent: 'center',alignItems: 'center',height: '100%',position: 'absolute',top: '-19px',right:'8px'}}>
                                            <CircularProgress thickness="4.4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                                        </Box>
                                    :
                                        null
                                }
                              </Box>
                          }
                          <Box
                              sx={{
                                display: showForgotPwdVw && !resetLinkSent ? 'flex' : 'none',
                                alignItems: 'center',
                                justifyContent:"space-between",
                                fontFamily:"Poppins",
                                margin: '8px',
                                textAlign: 'center',
                                flexDirection: 'row-reverse',
                              }}
                          >
                              <Button
                                variant="contained"
                                style={{width: '240px', margin: '8px 6px',
                                color: 'rgba(255,255,255,0.87)',                                
                                fontSize:"17px",
                                fontWeight:600,
                                fontFamily:'Poppins',
                                borderRadius:"12px",
                                backgroundColor: '#4190c7',}}
                                onClick={() => forgotPassword()}
                              >
                                RESET PASSWORD
                              </Button>
                              <Button
                                variant="text"
                                style={{
                                  minWidth: '88px',
                                  margin: '8px 6px',
                                  fontSize:"17px",
                                  fontWeight:600,
                                  borderRadius:"12px",
                                  fontFamily:'Poppins',
                                  color: 'rgba(0,0,0,0.87)',
                                }}
                                onClick={() => hideForgotPwd()}
                              >
                                CANCEL
                              </Button>
                              {
                                isLoading ?
                                    <Box sx={{width: 'calc(100% - 17px)',display: 'flex',justifyContent: 'center',alignItems: 'center',height: '100%',position: 'absolute',top: '-19px',right:'8px'}}>
                                        <CircularProgress thickness="4.4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                                    </Box>
                                :
                                    null
                              }
                          </Box>
                          <Box
                                sx={{
                                    display: resetLinkSent ? 'block' : 'none',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                }}
                          >
                            <Typography mt={2}
                                style={{
                                    color: "#008000",
                                    fontFamily:"Poppins"
                                }}
                            >
                                <Done sx={{position:"relative",top:"6px",right:"4px" }}/>
                                    Success
                            </Typography>
                            <Typography mt={2}
                                style={{
                                  fontFamily:"Poppins",
                                }}
                            >
                                An email with reset password link has been sent to your Email Id.
                            </Typography>
                          </Box>
                          <hr style={{ display: showSecondStep ? 'none' : showForgotPwdVw ? 'none' : 'flex',position:"relative",top:"40px",color:"rgb(0,122,255)", borderWidth:"1px"}}/>
                          <Typography
                            mt={2}
                            style={{
                              display: showSecondStep ? 'none' : showForgotPwdVw ? 'none' : 'flex',
                              marginTop: "28px",
                              fontWeight: '500',
                              fontFamily:"Poppins",
                              fontSize: '16px',
                              justifyContent: 'space-around',
                            }}
                          >                            
                            <span style={{cursor: 'pointer',background:"#fff",position:"relative",zIndex:"10", padding:"0px 10px 0px 10px",color:"rgb(0,122,255)"}} onClick={() => showForgotPwd()}>
                              Forgot Password?
                            </span>
                            <span style={{cursor: 'pointer',background:"#fff",position:"relative",zIndex:"10",padding:"0px 10px 0px 10px",color:"rgb(0,122,255)"}} onClick={() => showSignUpView()}>
                              Sign Up
                            </span>
                          </Typography>
                        </Box>
                    :
                        <Box
                          className="logInWidth"
                          sx={{
                            backgroundColor: !isLoading ? '#fff' : '#fff',
                            position: 'relative',
                            width: '100% !important',
                            margin: '30px',
                            fontFamily:"Poppins",
                            opacity: isLoading && !resetLinkSent ? 0.5 : null,
                          }}
                        >
                          <Box sx={{height:"150px", width:"100%"}}>
                            <img src={caribouLogo} height={135} width={263} />
                          </Box>
                          <Box sx={{height:"41px", width:"100%",color: 'rgb(0,122,255)'}}>
                            <Typography sx={{fontSize: '27px', fontFamily: 'Poppins', fontWeight:'600'}}>
                              Sign Up
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex',flexDirection: 'column', marginTop: '18px', display: isSuccess ? 'none' : 'block'}} >
                            <Box className="nameTextField" sx={{display: 'flex', flexDirection:' column'}}>
                                <TextField
                                  type="text"
                                  fullWidth
                                  variant= "standard"
                                  label = "First name"
                                  value = {firstName}
                                  inputProps={{
                                    maxlength: 20
                                  }}
                                  onChange={(event) => {
                                    let value = event.target.value;
                                    value = value === " " || value.indexOf(" ") === 0 ? checkSpace(value) : value;
                                    setFirstName(value);
                                    setFirstNameTextLength(value !== "" && typeof value !== 'undefined' && value ? value.length + "/20" : "0/20");
                                    window.userData.firstName = value;
                                    window.userData.firstNameTextLength = firstNameTextLength;
                                    setFirstNameErrorText(!isValidName(window.userData.firstName))
                                    checkIsValidDataOrNot();
                                  }}
                                  onBlur={() => checkIsValidDataOrNot()}
                                />
                                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent:"space-between"}}>
                                    <FormHelperText error sx={{marginBottom:'5px', visibility: firstNameErrorText ? 'visible' : 'hidden' }} variant="standard" > Invalid First Name. </FormHelperText>
                                    <Box sx={{fontSize:"12px",color: firstName !== "" && firstName && typeof firstName !== 'undefined' ? "#000000DE" : "red"}}>
                                      {firstName !== "" && firstName && typeof firstName !== 'undefined' ? firstName.length + "/20" : "0/20"}
                                    </Box>
                                </Box>
                            </Box>
                            <Box className="nameTextField" sx={{display: 'flex', flexDirection:' column'}}>
                                <TextField
                                  fullWidth
                                  variant= "standard"
                                  label = "Last name"
                                  value={lastName}
                                  inputProps={{
                                    maxlength: 20
                                  }}
                                  onChange={(event) => {
                                    let value = event.target.value;
                                    value = value === " " || value.indexOf(" ") === 0 ? checkSpace(value) : value;
                                    setLastName(value);
                                    setLastNameTextLength(value !== "" && typeof value !== 'undefined' && value ? value.length + "/20" : "0/20");
                                    window.userData.lastName = value;
                                    window.userData.lastNameTextLength = lastNameTextLength;
                                    setLastNameErrorText(!isValidName(window.userData.lastName))
                                    checkIsValidDataOrNot();
                                  }}
                                  onBlur={() => checkIsValidDataOrNot()}
                                />
                                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent:"space-between"}}>
                                    <FormHelperText error sx={{marginBottom:'5px', visibility: lastNameErrorText ? 'visible' : 'hidden' }} variant="standard" > Invalid Last Name. </FormHelperText>
                                    <Box sx={{fontSize:"12px",color: lastName !== "" && lastName && typeof lastName !== 'undefined' ? "#000000DE" : "red"}}>
                                      {lastName !== "" && lastName && typeof lastName !== 'undefined' ? lastName.length + "/20" : "0/20"}
                                    </Box>
                                </Box>
                            </Box>
                            <Box className="nameTextField" sx={{ display: 'flex', flexDirection: 'column'}}>
                                <TextField
                                  fullWidth
                                  variant= "standard"
                                  label = "Email"
                                  value={email}
                                  InputProps={{
                                      autoComplete : 'new-password',
                                      inputProps:{
                                        maxlength: 40
                                      }
                                  }}
                                  onChange={(event) => {
                                    let value = event.target.value;
                                    value = value === " " || value.indexOf(" ") === 0 ? checkSpace(value) : value;
                                    setEmail(value);
                                    setEmailTextLength(value !== "" && typeof value !== 'undefined' && value ? value.length + "/40" : "0/40");
                                    window.userData.email = value;
                                    setEmailErrorText(!isValidEmail(window.userData.email))
                                    checkIsValidDataOrNot();
                                  }}
                                  onBlur={() => checkIsValidDataOrNot()}
                                />
                                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent:"space-between"}} >
                                    <FormHelperText error sx={{marginBottom:'5px', visibility: emailErrorText ? 'visible' : 'hidden' }} variant="standard" > Email is required. </FormHelperText>
                                    <Box sx={{fontSize:"12px",color: email !== "" && email && typeof email !== 'undefined' ? "#000000DE" : "red"}}>
                                        {email !== "" && email && typeof email !== 'undefined' ? email.length + "/40" : "0/40"}
                                    </Box>
                                </Box>
                            </Box>
                            <Box style={{display:'flex',flexDirection:'column', position:'relative', marginBottom: '10px'}}>
                                <Box style={{display:'flex',flexDirection:'row',position:'relative'}}>
                                  <div style={{height:'24px',width:'57px',position: 'absolute',zIndex: '10',marginTop: '30px',marginLeft: '46px'}}></div>
                                  <TextField
                                    id="mobileField"
                                    label="Mobile Number"
                                    variant="standard"
                                    value={phoneNumber}
                                    sx={{width: '100%', margin: '10px 0px', position: 'relative'}}
                                    InputLabelProps = {{shrink:true}}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start" sx={{paddingLeft:'4px'}}>
                                          <PhoneInput
                                            international
                                            defaultCountry = {countryFlag}
                                            onCountryChange = {(val) => {
                                              window.userData.countryCode = val;
                                              setCountryFlag(val);
                                            }}
                                            countryCallingCodeEditable={false}
                                            placeholder="Country Code"
                                            value={countryCode}
                                            onChange={(value) => handleOnChange(value)}
                                            onBlur={() => checkIsValidDataOrNot()}
                                            style={{ width: '100px' }}
                                          />
                                        </InputAdornment>
                                      ),
                                      inputProps: {
                                        autoComplete: 'new-password',
                                        maxLength: 15,
                                        fontSize:"20px",
                                      },
                                    }}
                                    onChange={(event) => {
                                      let number = event.target.value;
                                      setPhoneNumber(number);
                                      window.userData.phoneNumber = number ? number : "";
                                      setPhoneNumberErrorText(!window.userData.phoneNumber.match("^[0-9]+$") && window.userData.phoneNumber)
                                      checkIsValidDataOrNot()
                                    }}
                                    onBlur={() => checkIsValidDataOrNot()}
                                  />
                              </Box>
                              <Box sx={{display:'flex', position: 'absolute', top:'55px', left:'53px'}}>
                                <FormHelperText sx={{marginBottom:'5px', visibility: phoneNumberErrorText ? 'visible' : 'hidden' }} variant="standard" error > Enter valid mobile number. </FormHelperText>
                              </Box>
                            </Box>
                            <TextField
                              fullWidth
                              select
                              disabled
                              variant= "standard"
                              label = "Role"
                              defaultValue = "Admin"
                              InputProps={{
                                  autoComplete : 'new-password',
                              }}
                              sx={{display: 'none'}}
                            >
                            {roles.map((role) => (
                               <MenuItem key={role.value} value={role.value}>
                                 {role.label}
                               </MenuItem>
                            ))}
                            </TextField>
                            <Box sx={{fontSize:'smaller', justifyContent:'center',color:'red', display: 'flex', marginTop:'15px', visibility: signUpStatus ? 'visible' : 'hidden' }}>
                              { showConflictMessage ? "This user already exists!" : "Error occurred while signing up. Please try again." }
                            </Box>
                            <Box sx={{margin:'15px 0px 0px', display: 'flex',justifyContent:'space-between',alignItems: 'center',fontFamily:'Poppins',textAlign: 'center'}} >
                              <Button style={{cursor : 'pointer', color:'black', marginRight: '10px', fontSize:"17px",fontWeight:600, fontFamily:'Poppins', borderRadius:"12px",}} onClick={()=> showSignUpView()}>
                                  Cancel
                              </Button>
                              <Button sx={{cursor : 'pointer', color: 'rgba(255,255,255,0.87)', fontSize:"17px",fontWeight:600,fontFamily:'Poppins',borderRadius:"12px",color: 'rgba(255,255,255,0.87)', backgroundColor: (!isValidDataOrNot ? 'none' : '#4190c7'), width:'240px','&.MuiButtonBase-root:hover': {background: '#4190c7'},}} variant="contained" disabled= {!isValidDataOrNot} onClick={()=> createPortalUser()}>
                                  Sign Up
                              </Button>
                            </Box>
                          </Box>
                          {
                            isLoading ?
                                <Box sx={{width: 'calc(100% - 17px)',display: 'flex',justifyContent: 'center',alignItems: 'center',height: '100%',position: 'absolute',top: '-19px',right:'8px'}}>
                                    <CircularProgress thickness="4.4" sx={{height:'32px !important',width: '32px !important',color: 'rgb(20,19,139) !important'}}/>
                                </Box>
                            :
                                null
                          }
                          {
                            isSuccess ?
                                <Box sx={{display: 'flex', flexDirection:'column', alignItems: 'center',justifyContent:'center', margin:'10px', padding:'10px'}}>
                                    <Box sx={{color: 'green', fontSize:'large', margin:'10px', display:'flex', justifyContent:'center'}}> Success </Box>
                                    <Box>An email with set password link has been sent to your Email Id.</Box>
                                </Box>
                            :
                                null
                          }
                        </Box>
                    }
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
    </>
  );
}
